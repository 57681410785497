


/*UserCard */
export class UserCard {
  /**id*/
  /**主键*/

  public id!: string;
  /**创建人*/

  public createBy!: string;
  /**创建日期*/

  public createTime!: Date;
  /**更新人*/

  public updateBy!: string;
  /**更新日期*/

  public updateTime!: Date;
  /**所属部门*/

  /**nft_tx*/

  public nftTx!: string;
  /**nft_确认状态*/

  /**持卡人姓名*/

  public name: string;
  /**银行卡号*/

  public cardNo: string;
  /**银行名称*/

  public bankName: string;
  /**开户行*/

  public bankAddress: string;

  /**卡片类型*/

  public cardType: string;


  /**信用卡*/

  public cardDate!: string;

  /**信用卡*/

  public cardSign!: string;
}

