'use strict'

import React, { Component } from 'react'
import {
  Switch,
  NavBar,
  Checkbox,
  Radio,
  Input,
  Tabs,
  TabBar,
  Badge,
} from 'antd-mobile'

import styles from './assets/index.module.css'

const print = function (value) {}
class DDS_lanhupage_0 extends Component {
  state = {}
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['group_1']} flex-row justify-between`}>
          <div className={`${styles['group_2']} flex-col`}>
            <div className={`${styles['image-wrapper_1']} flex-col`}>
              <img
                className={`${styles['image_1']}`}
                src={require('./assets/img/ps9me6hge0foi2gti7r3nlzzdqqiga7teb8301b5cb9-f05f-44db-95bb-9664acb11f81.png')}
              />
            </div>
          </div>
          <img
            className={`${styles['image_2']}`}
            src={require('./assets/img/ps0v6ictdpok3qejtxc06uhdf611qx30s64u3cf3fb33-35c6-4d53-9841-3c08b5cd0846.png')}
          />
        </div>
        <div className={`${styles['group_3']} flex-row`}>
          <div className={`${styles['image-wrapper_2']} flex-col`}>
            <img
              className={`${styles['image_3']}`}
              src={require('./assets/img/pse1sw4epofroei2ldhjx1oll43ls1ir6wd3e903a9-1a65-4443-936e-922210ed7edb.png')}
            />
          </div>
        </div>
        <div className={`${styles['text-wrapper_1']} flex-row`}>
          <span className={`${styles['text_1']}`}>
            恭喜您成为哇塞星球玩家，静待启航！
          </span>
        </div>
        <div className={`${styles['group_4']} flex-row`}>
          <div className={`${styles['text-wrapper_2']} flex-col`}>
            <span className={`${styles['text_2']}`}>返回个人中心&gt;&gt;</span>
          </div>
        </div>
      </div>
    )
  }
}
export default DDS_lanhupage_0
