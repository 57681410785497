import { SendSms, phoneLogin, base36_to_num } from 'hooks/RaidConfig'
import { ToastsProvider, ToastListener } from '../../contexts/ToastsContext'

import { SmsInfo } from 'hooks/modules/SmsInfo'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Dropdown,
  InjectedModalProps,
  Image,
  CloseIcon,
  Button,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import QRCode from 'qrcode.react'
import React, { useState, useEffect, Component, useRef } from 'react'
import styled from 'styled-components'

import {
  Switch,
  NavBar,
  Checkbox,
  Radio,
  Input,
  Tabs,
  TabBar,
  Badge,
} from 'antd-mobile'
import useToast from 'hooks/useToast'

import LoginUserInfo from '../common/LoginUserInfo'

const StyledModalContainer = styled.div`
  position: fixed;
  background: url('./assets/img/paybg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 834px;
  height: 483px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  width: 100vw;
  height: 100vh;
`

const Row = styled.div`
  width: 100%;
  color: #fff;
  font-size: 20px;
`

const RowCenter = styled.div`
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 30%;
  margin-top: 50px;
`

const RowHead = styled.div`
  width: 100%;
  border-bottom: 1px solid #fff;
`
import { parse } from 'querystring'

interface ModalProps extends InjectedModalProps {
  setPayCardView
  buyorder
}

const PayCardPc: React.FC<ModalProps> = ({ setPayCardView, buyorder }) => {
  const { toastSuccess, toastError } = useToast()
  const { isMobile } = useMatchBreakpoints()
  const [lodding, setLodding] = useState(false)
  //倒计时  start
  const [time, setTime] = useState(0) //倒计时时间
  const timeRef = useRef(null) //设置延时器

  const getPageQuery = () => parse(window.location.href.split('?')[1])

  const locationQuery = getPageQuery()

  // 读取base64字符串
  let buffer = locationQuery.t.toString()

  console.log('invite_t', buffer)

  // 读取base64字符串
  let p = locationQuery.p.toString()

  console.log('invite_p', p)

  useEffect(() => {
    //如果设置倒计时且倒计时不为0
    if (time && time !== 0)
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1)
      }, 1000)
    //清楚延时器
    return () => {
      clearTimeout(timeRef.current)
    }
  }, [time])

  console.log('time', time)

  return (
    <StyledModalContainer>
      <RowHead>
        <Flex>
          <Image m={2} width={40} height={40} src={'assets/img/hui2x.png'} />
          <Text mt={3} fontWeight={700} color="#FFFFFF">
            哇塞数字科技
          </Text>{' '}
        </Flex>
      </RowHead>
      <RowCenter>
        <Row style={{ marginTop: '0px' }}>
          <Image width={682} height={12} src={'assets/img/titel.png'} />
        </Row>
        <Row
          style={{ marginTop: '15px', textAlign: 'center', color: '#C4BDBD' }}
        >
          订单金额
        </Row>
        <Row style={{ marginTop: '15px', textAlign: 'center' }}>
          <Flex ml={110}>
            <Text fontSize="25px" color="#B5D4FA" mt={4}>
              ￥
            </Text>
            <Text
              fontSize="50px"
              fontWeight={700}
              fontFamily={'SourceHanSansCN-Heavy'}
              color="#B5D4FA"
            >
              {p}
            </Text>
          </Flex>
        </Row>

        <Row
          style={{ marginTop: '15px', textAlign: 'center', color: '#C4BDBD' }}
        >
          <QRCode
            value={buffer} //地址
            size={300} // 二维码的大小
            fgColor="#000000" // 二维码的颜色
            style={{ margin: 'auto', background: '#fff' }}
          />
        </Row>
        <Row
          style={{ marginTop: '25px', textAlign: 'center', color: '#C4BDBD' }}
        >
          订单支付成功后，可在“个人中心”查看订单详情
        </Row>
        <Row
          style={{ marginTop: '25px', textAlign: 'center', color: '#B5D4FA' }}
        >
          支付成功前请勿关闭窗口，以防丢失
        </Row>
      </RowCenter>
    </StyledModalContainer>
  )
}

export default PayCardPc
