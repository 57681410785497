import {
    InjectedModalProps
} from '@pancakeswap/uikit';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { createGlobalStyle } from 'styled-components';
import CommonHead from './lanhu_huaban2/CommonHead2'
import Login from './lanhu_huaban2/LoginNew'

const PlaerDiv =  createGlobalStyle`
.splash-screen-container {
    position: relative; 
    width:100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  /* 默认样式，适用于大屏幕和桌面设备 */
  .video-background {
    position: absolute;
    top: 0;
    left: 0; 
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .video-react video {
    width:100%;
    height: 100%;
    object-fit: cover; /* 视频适应容器并充满全屏 */
    /* 防止视频出现蓝色边框 */
    outline: none;
  } 


  @media screen and (max-width: 768px) {
    .video-background {
      /* 适配手机屏幕 */
      /* 根据需要调整样式 */
    }
  
    .video-react video {
      /* 适配手机屏幕 */
      /* 根据需要调整样式 */
    }
  }
  
  
  .enter-button-container {
    display: flex;
    height:88.9px;
    width:231px;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    top: 45%; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
   
  }
  
  .enter-button-container button {
 
    font-size: 26px;
    height:88.9px;
    width:231px;
    color: #FEFEFE;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background:url('./assets/img/enter.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; 
  }
  
  
`;
interface ModalProps extends InjectedModalProps {
    setEnterView: (boolean)=>{}  ;
}

const SplashScreen: React.FC<ModalProps> = ({setEnterView}) => {
  const [loginView, setLoginView] = useState(0)
  const [videoEnded, setVideoEnded] = useState(false);
  const videoRef = useRef(null);
  const lastSecondRef = useRef(null);

  useEffect(() => {
    // 在组件挂载时开始播放视频
    setVideoEnded(false);
  }, []);

  const handleVideoEnd = () => {
    setVideoEnded(true); 
    const randomNumber = Math.random() * 0.5;

    videoRef.current.seekTo(18+randomNumber, 'seconds');  
       
  };
/* 
  const handleVideoProgress = ({ playedSeconds, loadedSeconds }) => { 

    console.log("playedSeconds:",playedSeconds)

    // 当视频结束时，并且视频还未处于最后一秒，将播放位置设置为最后一秒
    if (videoEnded  ) {

        videoRef.current.seekTo(playedSeconds - 1, 'seconds');  
       
    } 
  }; */

  return (
 <>   <PlaerDiv /> 
  {loginView >= 1 && (
          <Login setLoginView={setLoginView} typesss={loginView} />
        )}
<CommonHead setLoginView={setLoginView} loginView={loginView} />

    <div className="splash-screen-container">
      <div className="video-background">
        <ReactPlayer className="video-react"
          ref={videoRef}
          url="./assets/img/picc.mp4" // 替换为实际视频文件的路径
          playing
          loop={false}
          muted 
          playsinline 
          width="100vw"
          height="100vh"
          onEnded={handleVideoEnd}
         /*  onProgress={handleVideoProgress}
          progressInterval={100} */
        />
      </div>
     {/*  {!videoEnded && ( */}
        <div className="enter-button-container">
          <button onClick={() => {setEnterView(false)}}>登&nbsp;陆&nbsp;飞&nbsp;船</button>
        </div>
     {/*  )} */}
    </div></>
  );
};

export default SplashScreen;
