import React, { Component } from 'react'
import {
  Switch,
  NavBar,
  Checkbox,
  Radio,
  Input,
  Tabs,
  TabBar,
  Badge,
} from 'antd-mobile'
import { useState } from 'react'
import styles from './assets/index.module.css'

const print = function (value) {}
class DDS_lanhupage_0 extends Component {
  state = {}
  setValue(val) {}
  render() {
    const [connected, setConnected] = useState(false)

    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['group_1']} flex-row`}>
          <div className={`${styles['section_1']} flex-col align-center`}>
            <div className={`${styles['section_2']} flex-col align-center`}>
              <div className={`${styles['box_1']} flex-col align-center`}>
                <div className={`${styles['group_2']} flex-col align-center`}>
                  <div
                    className={`${styles['section_3']} flex-col align-center`}
                  >
                    <div
                      className={`${styles['image-wrapper_1']} flex-col align-center`}
                    >
                      <img
                        className={`${styles['thumbnail_1']}`}
                        src={require('./assets/img/ps6jcfb1o4f2n9d5ygfb1p98j9y2gs7x7cda0197d67-0f83-4dc7-99a2-46119f2b827d.png')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['section_4']} flex-col align-center`}>
            <div className={`${styles['section_5']} flex-col align-center`}>
              <div className={`${styles['section_6']} flex-col align-center`}>
                <div className={`${styles['block_1']} flex-col align-center`}>
                  <div className={`${styles['group_3']} flex-col align-center`}>
                    <div
                      className={`${styles['image-wrapper_2']} flex-col align-center`}
                    >
                      <img
                        className={`${styles['thumbnail_2']}`}
                        src={require('./assets/img/ps9l82pu5cu6tt0mhoi1z9rjqwchwcuci386a1f7a97-0911-4f80-80c6-1a51543d3f37.png')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className={`${styles['label_1']}`}
            src={require('./assets/img/psde3bdmo2cqrm9vrm35eks5mp75rutthh3fcb3175-5fe5-4131-8589-953db9ae6add.png')}
          />
        </div>
        <div
          className={`${styles['image-wrapper_3']} flex-row justify-between`}
        >
          <img
            className={`${styles['label_2']}`}
            src={require('./assets/img/psokjp9kvp5xlxd317jqlzgswmydkyws3qg1195638c-6de9-4394-9a73-f8337acca3bf.png')}
          />
          <img
            className={`${styles['image_1']}`}
            src={require('./assets/img/psmktvy0w46yrqhv6jojphtnv605s7mrp39f1b69c-385b-4df1-9fba-af567eb3b197.png')}
          />
        </div>
        <div className={`${styles['group_4']} flex-row justify-between`}>
          <img
            className={`${styles['image_2']}`}
            src={require('./assets/img/pszk052zrtcbq4hep3k92q8zylg734qhffca636c6-522a-4210-b93e-adae575373cd.png')}
          />
          <div
            className={`${styles['text-wrapper_1']} flex-col justify-between`}
          >
            <span className={`${styles['text_1']}`}>商品数量：18</span>
            <span className={`${styles['text_2']}`}>应付金额：29999元</span>
          </div>
        </div>
        <div className={`${styles['group_5']} flex-row`}>
          <div className={`${styles['text-wrapper_2']}`}>
            <span className={`${styles['text_3']}`}>您已享受星球</span>
            <span className={`${styles['text_4']}`}>45%返利</span>
            <span className={`${styles['text_5']}`}>优惠！</span>
          </div>
        </div>
        <div className={`${styles['text-wrapper_3']} flex-row`}>
          <span className={`${styles['text_6']}`}>确认付款</span>
        </div>
        <div
          className={`${styles['image-wrapper_4']} flex-row justify-between`}
        >
          <img
            className={`${styles['image_3']}`}
            src={require('./assets/img/psxvf6874j5hdjr6h2gme46apilnrj3m6ad99f6c75-1a02-427f-b6c4-00458daba4b2.png')}
          />
          <img
            className={`${styles['label_3']}`}
            src={require('./assets/img/ps1xylgl7hg6nskujyxabbwbmxp9334zb4p0a00d250-74d2-4089-8d16-4954c766f8fa.png')}
          />
        </div>
        <div className={`${styles['group_6']} flex-row`}>
          <div className={`${styles['section_7']} flex-col`}>
            <div className={`${styles['block_2']} flex-col`}>
              <div className={`${styles['image-wrapper_5']} flex-col`}>
                <img
                  className={`${styles['image_4']}`}
                  src={require('./assets/img/ps7jt85c7kxj5abb2f2izf298ixj03egnsvf8b7f6d6-619b-411b-9f86-dbdb8a5b6559.png')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default DDS_lanhupage_0
