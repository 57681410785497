import React, { useState, useEffect, Component } from 'react'
import {
  SendSms,
  base36_to_num,
  num_to_base36,
  wasaiPerson,
  openKu,
  airdrop,
} from 'hooks/RaidConfig'
import { submitWithdraw } from 'hooks/HRaidConfig'
import styled from 'styled-components'
import { loginOut, WASAI_URL } from 'hooks/RaidConfig'
import { CopyButton } from './CopyButton'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Button,
  InjectedModalProps,
  CloseIcon,
  ModalTitle,
  IconButton,
  Input,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'

import { UserGoods } from 'hooks/modules/UserGoods'
import CopyAddress from './CopyAddress'

const InfoBody = styled.div`
  width: 300px;
  min-width: 300px;
  height: 300px;
`

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 450px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const Wrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dropdown};
  border-radius: 16px;
  position: relative;
`

const Address = styled.div`
  flex: 1;
  position: relative;
  padding-left: 16px;

  & > input {
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
`

const BossSonRow = styled.div`
  border: dashed 1px #2c2a2a;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
`

interface ModalProps extends InjectedModalProps {}

const LoginUserInfo: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [inviteLink, setInviteLink] = useState('')
  const [inviteCodd, setInviteCodd] = useState('')

  const [account, setAccount] = useState<any>()
  const [admin, setAdmin] = useState<any>()
  const [wasaiInvite, setWasaiInvite] = useState<any>()
  const [withdrawAmount, setWithdrawAmount] = useState('')

  const [airdropAmount, setAirdropAmount] = useState('')
  const [airdropUserPhone, setAirDropUserPhone] = useState('')

  const [wasaiInviteChilds, setWasaiInviteChilds] = useState<any>([])

  const queryData = async () => {
    const result = await wasaiPerson()
    if (result?.result) {
      setAccount(result?.result?.account)
      setAdmin(result?.result?.Admin)
      setWasaiInvite(result?.result?.wasaiInvite)
      setWasaiInviteChilds(result?.result?.wasaiInviteChilds)
    }
  }

  useEffect(() => {
    let myinvite = localStorage.getItem('myinvite')

    if (myinvite) {
      setInviteLink(WASAI_URL + '?invite=' + num_to_base36(myinvite))
      setInviteCodd(num_to_base36(myinvite))
    }
    queryData()
  }, [])

  //查询账户余额

  //用户提现
  const handleWithdraw = async () => {
    const payload = {
      coin: 'RMB',
      tokenBase: 'BSC',
      amount: parseFloat(withdrawAmount),
      addressTo: '',
    }

    const resp = await submitWithdraw(payload)
    if (resp.status === 200) {
      toastSuccess('提交成功！')
      queryData()
    } else {
      toastError(resp?.message)
    }
  }

  //开启云仓
  const handleKu = async () => {
    const resp = await openKu()
    if (resp.status === 200) {
      toastSuccess('修改成功！')
      queryData()
    } else {
      queryData()
      toastError(resp?.message)
    }
  }

  //发放空投
  const handleAirDrop = async () => {
    if (!airdropUserPhone) {
      toastError('手机号不能为空')
      return
    }
    if (!airdropAmount) {
      toastError('数量不能为空')
      return
    }
    const userGoods = new UserGoods()
    userGoods.uid = airdropUserPhone
    userGoods.amount = Number(airdropAmount)
    const resp = await airdrop(userGoods)
    if (resp.success) {
      toastSuccess('发放成功！')
    } else {
      toastError(resp?.message)
    }
  }

  const onInputChange = (e) => {
    setWithdrawAmount(e.currentTarget.value.replace(/[^\d]/g, ''))
  }

  const onInputChangeAirdropAmount = (e) => {
    setAirdropAmount(e.currentTarget.value.replace(/[^\d]/g, ''))
  }

  const onInputChangeUserPhone = (e) => {
    setAirDropUserPhone(e.currentTarget.value)
  }

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'信息'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>

      <BossSonRow>
        <Text>邀请链接:</Text> <CopyAddress account={inviteLink} />
      </BossSonRow>
      <BossSonRow>邀请码：{inviteCodd}</BossSonRow>
      <BossSonRow>会员等级：{wasaiInvite?.userLevelName}</BossSonRow>
      <BossSonRow>已邀请人数：{wasaiInviteChilds?.length}</BossSonRow>

      <BossSonRow>
        账户盈利：
        {(account?.otcNormalBalance + account?.otcFrozenBalance).toFixed(2)} ￥
      </BossSonRow>

      {/* {admin && (
        <BossSonRow>
          数量
          <Input
            style={{ width: '50px' }}
            onChange={onInputChangeAirdropAmount}
            value={airdropAmount}
          />
          手机
          <Input
            style={{ width: '150px' }}
            onChange={onInputChangeUserPhone}
            value={airdropUserPhone}
          />
          <Button
            style={{ background: 'rgb(211 49 187)' }}
            onClick={handleAirDrop}
          >
            发放盲盒
          </Button>
        </BossSonRow>
      )} */}

 {/*      <BossSonRow>
        <Flex>
          <Button onClick={handleKu}>
            {wasaiInvite?.isKu == 0 ? '开启' : '关闭'}出售库存
          </Button>
          <Text ml={4} mt={3}>
            当前状态为{wasaiInvite?.isKu == 1 ? '开启' : '关闭'}{' '}
          </Text>
        </Flex>
      </BossSonRow> */}
    </StyledModalContainer>
  )
}
export default LoginUserInfo
