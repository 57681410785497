


export class SmsInfo {

  /**mobile*/
  public mobile!: string;
  /*smsmode */
  public smsmode!: string;
  /*captcha */
  public captcha!: string;
  /*captcha */
  public inviteCode!: string;
  /*captcha */
  public types!: string;
}