import { buyNft, queryTotalNft, wasaiPerson } from 'hooks/RaidConfig'
import MyContext from '../common/CommonProvider'

import { BuyOrder } from 'hooks/modules/BuyOrder'

import {
  Button,
  Flex,
  Image,
  InjectedModalProps,
  Progress,
  Text,
  useMatchBreakpoints, 
} from '@pancakeswap/uikit'

import React, {
  Component,
  useContext,
  useEffect,
  useState
} from 'react'

import {
  Input
} from 'antd-mobile'
import useToast from 'hooks/useToast'

import styled, { createGlobalStyle, css } from 'styled-components'

import Login from '../lanhu_huaban2/LoginNew'

import CommonHead from '../lanhu_huaban2/CommonHead2'
interface ModalProps extends InjectedModalProps { } 

const Row = styled.div`
  @media screen and (max-width: 968px) {
    width: 100%;
  }
`

const RowFot = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
 
 


const StyledFoot = styled.div`
  width: 80%;
  overflow-y: auto;
  min-height: 90px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px;
  padding-top: 15px;
`

 


 

const Footer: React.FC = ({ }) => {
   
    const {
       
        setTeamCardView,
      } = useContext(MyContext)
  return (
    

        <StyledFoot>
          <RowFot>
            {' '}
            <Text
              fontSize="8px"
              fontWeight={300}
              lineHeight={2}
              color={'#909090'}
            >
              <a
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#909090',
                }}
                target={'_blank'}
                href="https://beian.miit.gov.cn"
              >
                网站备案：渝ICP备2023000789号
              </a>
            </Text>
          </RowFot>
          <RowFot>
           
            <Text
              fontSize="8px"
              fontWeight={300}
              lineHeight={2}
              color={'#909090'}
            >
             
              增值电信业务许可证：渝B2-20230191
            </Text>
          </RowFot>
          <RowFot>
            
            <Text
              fontSize="8px"
              fontWeight={300}
              lineHeight={2}
              color={'#909090'}
            >
              {' '}
              ©2023-2033 重庆哇塞宇宙数字科技有限公司 版权所有
            </Text>
          </RowFot>

          <RowFot style={{ paddingTop: '5px' }}>
            <Flex>
              <Text
                fontSize="10px"
                fontWeight={300}
                lineHeight={2}
                onClick={() => {
                  setTeamCardView(9)
                }}
              >
                联系我们
              </Text>
              <Text fontSize="10px" fontWeight={300} lineHeight={2} ml={25}>
                <a
                  style={{ textDecoration: 'none', color: '#fff' }}
                  target={'_blank'}
                  href="https://doc.wasai.space/2.html"
                >
                  隐私政策
                </a>
              </Text>
              <Text fontSize="10px" fontWeight={300} lineHeight={2} ml={25}>
                <a
                  style={{ textDecoration: 'none', color: '#fff' }}
                  target={'_blank'}
                  href="https://doc.wasai.space/1.html"
                >
                  用户协议
                </a>
              </Text>
            </Flex>
          </RowFot>
        </StyledFoot>
      
  )
}

 

export default Footer
