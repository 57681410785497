


export class UserInfo {

  /**主键*/
  public id!: string;
  /*realName */
  public realname!: string;
  /* userName*/
  public userName!: string;
  /* userName*/
  public username!: string;
  /** 更新日期 */
  public updateTime!: Date;


  /** 创建日期 */

  public createTime!: Date;


  /** 创建日期 */

  public status!: number;

  /*f2aId */

  public f2aId!: string;

  /* 子账号开启状态 */
  public activitiSync!: number;
  //登陆身份
  public userIdentity!: number;
}