import {
  Button,
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalTitle,
  Text,
  Image,
  ArrowDropUpIcon,
} from '@pancakeswap/uikit'
import { nftTransfer } from 'hooks/RaidConfig'
import { WasaiTransfer } from 'hooks/modules/WasaiTransfer'
import useToast from 'hooks/useToast'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 450px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const BossSonRow = styled.div`
  width: 100%;
  padding: 3px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  color: #fff;
`

const BossSonTopRow = styled.div`
  border-bottom: 1px solid white;

  display: flex;
  width: 220px;
  height: 42.5px;
  background: #ffffff;
  border-radius: 15px;
  margin: 0 7.5% 0;
`

const BossInput = styled(Input)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc0;
  outline: none;
  padding: 10px;
  width: 220px;
  font-size: 18px;
  color: #000;
  ::placeholder {
    font-size: 14px;
  }
`

interface ModalProps extends InjectedModalProps {}

const WasaiTransferPage: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()

  const [phone, setPhone] = useState('')
  const [amount, setAmount] = useState()

  const [view, setView] = useState('')

  //查询账户余额

  //用户提现
  const handleWasaiTransfer = async () => {
    const wasaiTransfer = new WasaiTransfer()

    wasaiTransfer.toUsername = phone
    wasaiTransfer.amount = amount

    const resp = await nftTransfer(wasaiTransfer)
    if (resp?.message) {
      setView(resp?.message.replace('Error,', ''))
    } else {
      setView('转赠成功!')
    }
  }

  const onInputChangeIncard = (e) => {
    setPhone(e.currentTarget.value)
  }

  const onInputChange = (e) => {
    setAmount(e.currentTarget.value.replace(/[^\d]/g, ''))
  }

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'藏品转赠'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>

      {view && (
        <>
          <BossSonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '100px',
            }}
          >
            <Text fontSize="25px" color="#fff" textAlign={'center'}>
              {view}
            </Text>
          </BossSonRow>

          <BossSonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {view == '转赠成功!' && (
              <Image
                onClick={() => {
                  setView('')
                }}
                width={50}
                height={50}
                src={'./assets/img/gougou.png'}
              />
            )}
            {view != '转赠成功!' && (
              <Image
                onClick={() => {
                  setView('')
                }}
                width={50}
                height={50}
                src={'./assets/img/xx.png'}
              />
            )}
          </BossSonRow>
        </>
      )}

      {!view && (
        <>
          <BossSonRow>
            <Text width={70}>手机号: </Text>
            <BossSonTopRow>
              <BossInput
                onChange={onInputChangeIncard}
                placeholder="请输入对方手机号"
                value={phone}
              />
            </BossSonTopRow>
          </BossSonRow>
          <BossSonRow>
            <Text width={70}>藏品数量:</Text>{' '}
            <BossSonTopRow>
              <BossInput
                onChange={onInputChange}
                placeholder="请输入转出数量"
                value={amount}
              />{' '}
            </BossSonTopRow>
          </BossSonRow>
          <BossSonRow>
            <Button style={{ width: '130px' }} onClick={onDismiss}>
              取消
            </Button>
            <Button
              onClick={handleWasaiTransfer}
              style={{ width: '130px', background: 'rgb(211 49 187)' }}
            >
              确认
            </Button>
          </BossSonRow>
        </>
      )}
    </StyledModalContainer>
  )
}
export default WasaiTransferPage
