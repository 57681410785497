import { submitWithdraw } from 'hooks/HRaidConfig'
import { num_to_base36, wasaiPerson } from 'hooks/RaidConfig'
import React, { useEffect, useState, useContext, useRef } from 'react'
import MyContext from './CommonProvider'

import {
  bindBankCardApply,
  bindBankCardInfo,
  WASAI_URL,
  BankCodeMap,
} from 'hooks/RaidConfig'

import { UserCardInfo } from 'hooks/modules/UserCardInfo'

import { Deposit } from 'hooks/modules/Deposit'

import styled from 'styled-components'

import {
  Button,
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalTitle,
  Text,
  useMatchBreakpoints,
  Image,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'

const StyledModalContainer = styled.div<{ card: string }>`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: ${({ card }) => card};

  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const BossSonRow = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
  margin-top: 0px;
  margin-left: 15%;
`

const BossSonTopRow = styled.div`
  border-bottom: 1px solid white;
  margin-top: 20px;
  display: flex;
  width: 95%;
  height: 42.5px;
  background: #ffffff;
  border-radius: 15px;
  margin-left: 2.5%;
`

const BossSonTopRowCardType = styled.div`
  margin-top: 10px;
  display: flex;
  width: 95%;
  height: 42.5px;

  margin-left: 2.5%;
`

const BankCardCard = styled.div`
  background: url('./assets/img/bank2x.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 90%;
  height: 130px;

  border-radius: 10px;

  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 30px;
`

const RowCenter = styled.div`
  background: url('./assets/img/kuang.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
`

const BossRowInit = styled.div`
  width: 100%;
  height: 50px;
  font-size: 15px;
  font-weight: normal;
  color: #fff;
  line-height: 20px;
  display: flex;
  padding-top: 15px;
  justify-content: center;
`
const BossBankText = styled(Text)`
  font-size: 16px;
  color: #fff;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: 400;
  letter-spacing: 4px;
  padding-top: 8px;
  width: 240px;
`

const BossBankText2 = styled(Text)`
  font-size: 12px;
  color: #b5d4fa;
  margin-left: 20px;
  font-weight: 400;
  letter-spacing: 4px;
  padding-top: 8px;
  width: 240px;
`

const BossBankText3 = styled(Text)`
  font-size: 20px;
  color: #fff;
  margin-left: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  padding-top: 8px;
  width: 90%;
  display: flex;
  text-align: center;
  justify-content: center;
`

const BossText = styled(Text)`
  font-size: 18px;
  color: #000;
  margin-left: 10px;
  font-weight: 400;
  letter-spacing: 4px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  text-align: justify;
  text-justify: inter-word;
  width: 98px;
`

const BossTextSign = styled(Text)`
  font-size: 18px;
  color: #000;
  margin-left: 10px;
  font-weight: 400;
  letter-spacing: 4px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  text-align: justify;
  text-justify: inter-word;
  width: 220px;
`

const BossTextDate = styled(Text)`
  font-size: 18px;
  color: #000;
  margin-left: 10px;
  font-weight: 400;
  letter-spacing: 4px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  text-align: justify;
  text-justify: inter-word;
  width: 150px;
`

const BossTextLink = styled(Text)`
  font-size: 14px;
  color: #94aee1;
  margin-left: -100px;
  font-weight: 400;
  letter-spacing: 4px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  text-align: justify;
  text-justify: inter-word;
  width: 100px;
`

const BossInput = styled(Input)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc0;
  outline: none;
  padding: 10px;
  width: 250px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  ::placeholder {
    font-size: 14px;
  }
`

const BossInputSign = styled(Input)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc0;
  outline: none;
  padding: 10px;
  width: 128px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  ::placeholder {
    font-size: 14px;
  }
`

const BossInputDate = styled(Input)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc0;
  outline: none;
  padding: 10px;
  width: 198px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  ::placeholder {
    font-size: 14px;
  }
`

const CardBody = styled.div`
  height: 480px;
  overflow-y: auto;
`
const ARow = styled(Text)`
  position: relative;
  font-size: 14px;
  color: #94aee1;
  font-weight: 400;
  text-align: right;
  margin-top: 20px;
  padding-right: 15px;
`
interface ModalProps extends InjectedModalProps {}

const BingChongCard: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [phone, setPhone] = useState('')
  const [smsCode, setSmsCode] = useState('')

  const [account, setAccount] = useState<any>()

  const [realName, setRealName] = useState('')
  const [bankCard, setBankCard] = useState('')
  const { setTeamCardView } = useContext(MyContext)

  const { isMobile } = useMatchBreakpoints()
  const [userInfo, setUserInfo] = useState<any>()
  const [viewGou, setViewGou] = useState(true)
  const [userCardInfoList, setUserCardInfoList] = useState<any[]>([])

  const [view, setView] = useState(0)

  const [hashInfo, setHashInfo] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [cardType, setCardType] = useState('')
  const [cardDate, setCardDate] = useState('')
  const [cardSign, setCardSign] = useState('')

  const [applyId, setApplyId] = useState('')
  const [time, setTime] = useState(0) //倒计时时间
  const [isloadding, setIsloadding] = useState(false)
  const [viewAdd, setViewAdd] = useState(true)
  const [height, setHeight] = useState('600px')

  const timeRef = useRef(null) //设置延时器

  useEffect(() => {
    //如果设置倒计时且倒计时不为0
    if (time && time !== 0)
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1)
      }, 1000)
    //清楚延时器
    return () => {
      clearTimeout(timeRef.current)
    }
  }, [time])
  /*检查登录状态 */
  useEffect(() => {
    queryData()
  }, [])

  const queryData = async () => {
    const result = await wasaiPerson()
    if (result?.result) {
      setUserInfo(result?.result?.userInfo)

      setUserCardInfoList(result?.result?.userCardList)

      /*    setBankCode(result?.result?.userCardInfo?.bankCode)
      setBankCard(result?.result?.userCardInfo?.bindCard)
      setBankName(result?.result?.userCardInfo?.bank) */
      setRealName(result?.result?.userInfo?.realname)
      setPhone(result?.result?.userInfo?.username)
      if (result?.result?.userCardList?.length > 0) {
        setViewAdd(false)
      }
    }
    setIsloadding(true)
  }

  const onInputChangeRealName = (e) => {
    setRealName(e.currentTarget.value)
  }

  const onInputBankName = (e) => {
    setBankName(e.currentTarget.value)
  }

  const onInputCardDate = (e) => {
    if (e.currentTarget.value.length <= 5) {
      let formatted = e.currentTarget.value.replace(/[^0-9]/g, '') // 只留下数字
      if (formatted.length > 2) {
        formatted = formatted.slice(0, 2) + '/' + formatted.slice(2) // 加上 /
      }
      setCardDate(formatted)
    }
  }

  const onInputCardSign = (e) => {
    if (e.currentTarget.value.length <= 3) {
      setCardSign(e.currentTarget.value)
    }
  }

  const onInputPhone = (e) => {
    setPhone(e.currentTarget.value)
  }

  const onInputSmsCode = (e) => {
    if (e.currentTarget.value.length <= 6) {
      setSmsCode(e.currentTarget.value)
    }
  }

  const onInputChangeIncard = async (e) => {
    setBankCard(e.currentTarget.value.replace(/[^\d]/g, ''))
    const cardNumber = e.target.value.replace(/\s/g, '') // 去除输入卡号中的空格
    if (
      cardNumber.length === 19 ||
      cardNumber.length === 16 ||
      cardNumber.length === 17 ||
      cardNumber.length === 18
    ) {
      const res = await fetch(
        `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${cardNumber}&cardBinCheck=true`
      )
      const result = await res.json()
      console.log('cardresultresultresult', result)
      if (result.stat === 'ok') {
        setBankCode(result.bank)
        const bankName = BankCodeMap[result.bank]
        if (bankName) {
          setBankName(bankName)
        }

        if (result.cardType == 'CC') {
          setHeight('750px')
        } else {
          setHeight('600px')
        }

        setCardType(result.cardType)
      }
    } else {
      setBankName('')
    }
  }

  const bindBankCardApplyInit = async () => {
    //绑定卡
    const userCardInfo = new UserCardInfo()
    if (realName) {
      userCardInfo.realname = realName
    } else {
      toastError('请填写姓名！')
      return
    }

    const regex = /^1[3456789]\d{9}$/ //使用此正则即可
    if (!regex.test(phone)) {
      toastError('Error', '手机格式错误!')
      return
    }

    userCardInfo.phone = phone

    if (bankCard) {
      userCardInfo.bindCard = bankCard
    } else {
      toastError('请填写银行卡号！')
      return
    }

    if (bankCode) {
      userCardInfo.bankCode = bankCode
      userCardInfo.bank = bankName
    } else {
      toastError('请填写银行卡名称！')
      return
    }

    if (cardType == 'CC') {
      if (cardDate) {
        userCardInfo.cardDate = cardDate.replace('/', '')
      } else {
        toastError('请填写信用卡有效日期！')
        return
      }

      if (cardSign) {
        userCardInfo.cardSign = cardSign
      } else {
        toastError('请填写信用卡背面持卡人签名后三位！')
        return
      }
    }

    userCardInfo.cardType = cardType

    const result = await bindBankCardApply(userCardInfo)
    if (result?.result?.applyId) {
      setApplyId(result?.result?.applyId)
      setTime(60)
    } else {
      toastError(result?.message)
    }
  }

  const bindBankCardInfoInit = async () => {
    //绑定卡
    const userCardInfo = new UserCardInfo()
    if (realName) {
      userCardInfo.realname = realName
    } else {
      toastError('请填写姓名！')
      return
    }

    const regex = /^1[3456789]\d{9}$/ //使用此正则即可
    if (!regex.test(phone)) {
      toastError('Error', '手机格式错误!')
      return
    }

    userCardInfo.phone = phone

    if (bankCard) {
      userCardInfo.bindCard = bankCard
    } else {
      toastError('请填写银行卡号！')
      return
    }

    if (bankCode) {
      userCardInfo.bankCode = bankCode
      userCardInfo.bank = bankName
    } else {
      toastError('请填写银行卡名称！')
      return
    }

    if (applyId) {
      userCardInfo.applyId = applyId
    } else {
      toastError('请先获取验证码！')
      return
    }

    if (smsCode) {
      userCardInfo.smsCode = smsCode
    } else {
      toastError('请填写验证码！')
      return
    }

    if (cardType == 'CC') {
      if (cardDate) {
        userCardInfo.cardDate = cardDate.replace('/', '')
      } else {
        toastError('请填写信用卡有效日期！')
        return
      }

      if (cardSign) {
        userCardInfo.cardSign = cardSign
      } else {
        toastError('请填写信用卡背面持卡人签名后三位！')
        return
      }
    }

    userCardInfo.cardType = cardType

    const result = await bindBankCardInfo(userCardInfo)
    if (result?.message) {
      toastError(result?.message)
    } else {
      toastSuccess('绑定成功')
      queryData()
    }
  }

  return (
    <>
      {/* 如果没有实名认证，提示先去认证 */}
      {isloadding && !userInfo?.f2aId && (
        <StyledModalContainer card={'600px'}>
          <CardHeader style={{ height: '70px' }}>
            <Flex mt={-3}>
              <ModalTitle>
                <Heading>{'充值绑定'}</Heading>
              </ModalTitle>
              <IconButton variant="text" onClick={onDismiss}>
                <CloseIcon width="24px" color="text" />
              </IconButton>
            </Flex>
          </CardHeader>
          <div
            style={{
              display: 'flex',
              marginTop: '100px',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Text textAlign={'center'} color="#FF0101">
              {'提示：您当前未实名认证'}
            </Text>
            <Text
              ml={1}
              textAlign={'center'}
              color="#7293FF"
              onClick={() => {
                setTeamCardView(6)
              }}
            >
              {' 前往认证>>'}
            </Text>
          </div>{' '}
        </StyledModalContainer>
      )}
      {isloadding && !viewAdd && userInfo?.f2aId && (
        <StyledModalContainer card={'600px'}>
          <CardHeader style={{ height: '70px' }}>
            <Flex mt={-3}>
              <ModalTitle>
                <Heading>{'充值绑定'}</Heading>
              </ModalTitle>
              <IconButton variant="text" onClick={onDismiss}>
                <CloseIcon width="24px" color="text" />
              </IconButton>
            </Flex>
          </CardHeader>
          {/* <TextRow>添加银行卡</TextRow> */}
          <CardBody>
            {userCardInfoList?.map((val) => (
              <BankCardCard>
                <BossBankText>{val.bank}</BossBankText>
                <BossBankText2>
                  {val.cardType == 'CC' ? '信用卡' : '储蓄卡'}
                </BossBankText2>
                <BossBankText3>
                  {val.bindCard
                    .replace(/\s/g, '')
                    .match(/.{1,4}/g)
                    ?.join(' ')}
                </BossBankText3>
              </BankCardCard>
            ))}
          </CardBody>
          <div
            style={{
              display: 'flex',
              marginTop: '15px',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Text
              style={{ cursor: 'pointer' }}
              textAlign={'center'}
              color="#7696D6"
              onClick={() => {
                setViewAdd(true)

                setBankCode('')
                setBankCard('')
                setBankName('')
              }}
            >
              {'添加'}
            </Text>
            <Text ml={1} textAlign={'center'} color="#FFF">
              {' 银行卡'}
            </Text>
          </div>
        </StyledModalContainer>
      )}
      {isloadding && viewAdd && userInfo?.f2aId && (
        <StyledModalContainer card={height}>
          <CardHeader style={{ height: '70px' }}>
            <Flex mt={-3}>
              <ModalTitle>
                <Heading>{'充值绑定'}</Heading>
              </ModalTitle>
              <IconButton variant="text" onClick={onDismiss}>
                <CloseIcon width="24px" color="text" />
              </IconButton>
            </Flex>
          </CardHeader>
          {/* <TextRow>添加银行卡</TextRow> */}
          <BossSonTopRow>
            <BossText>真实姓名:</BossText>
            <BossInput
              onChange={onInputChangeRealName}
              value={realName}
              placeholder="请输入真实姓名"
            />
          </BossSonTopRow>
          <ARow>支持银行卡列表</ARow>{' '}
          <BossSonTopRow>
            <BossText>银行卡号:</BossText>
            <BossInput
              onChange={onInputChangeIncard}
              value={bankCard}
              placeholder="请输入银行卡号"
            />
          </BossSonTopRow>
          <BossSonTopRowCardType>
            <BossText style={{ color: '#7696D6' }} color="#7696D6">
              {cardType == 'CC' ? '信用卡' : ''}
              {cardType == 'DC' ? '储蓄卡' : ''}
            </BossText>
          </BossSonTopRowCardType>
          <BossSonTopRow>
            <BossText>银行名称:</BossText>
            <BossInput
              readOnly
              onChange={onInputBankName}
              value={bankName}
              placeholder=""
            />
          </BossSonTopRow>
          {cardType == 'CC' && (
            <>
              <BossSonTopRow>
                <BossTextSign>信用卡签名条末三位:</BossTextSign>
                <BossInputSign
                  onChange={onInputCardSign}
                  value={cardSign}
                  placeholder=""
                />
              </BossSonTopRow>
              <BossSonTopRow>
                <BossTextDate>信用卡有效期:</BossTextDate>
                <BossInputDate
                  onChange={onInputCardDate}
                  value={cardDate}
                  placeholder="YY / MM"
                />
              </BossSonTopRow>
            </>
          )}
          <BossSonTopRow>
            <BossText>手机号:</BossText>

            <BossInput
              onChange={onInputPhone}
              value={phone}
              placeholder="188****8888"
            />
          </BossSonTopRow>
          <BossSonTopRow>
            <BossText>验证码:</BossText>

            <BossInput
              onChange={onInputSmsCode}
              value={smsCode}
              placeholder="请输入验证码"
            />
            <BossTextLink onClick={bindBankCardApplyInit}>
              {time > 1 && time}
              {time < 1 && '获取验证码'}
            </BossTextLink>
          </BossSonTopRow>
          <BossRowInit>
            <Flex style={{ overflow: 'hidden' }}>
              <RowCenter
                onClick={() => {
                  setViewGou(!viewGou)
                }}
              >
                {viewGou && (
                  <Image width={15} height={15} src={'./assets/img/gou.png'} />
                )}
              </RowCenter>
            </Flex>
            <Text>阅读并同意</Text>
            <Text color={'#94aee1'}>《银行卡快捷协议》</Text>
          </BossRowInit>
          <BossSonRow>
            <Button
              style={{ width: '160px', background: 'rgb(211 49 187)' }}
              onClick={bindBankCardInfoInit}
            >
              立即认证
            </Button>
          </BossSonRow>
        </StyledModalContainer>
      )}
    </>
  )
}
export default BingChongCard
