import { nftTransferHistory } from 'hooks/RaidConfig'
import React, { useEffect, useState } from 'react'

import {
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalTitle,
  Table,
  Td,
  Th,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
import styled from 'styled-components'

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 465px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

interface ModalProps extends InjectedModalProps {}

const NftTranferHistory: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [records, setRecords] = useState([])
  const [phone, setPhone] = useState('')

  useEffect(() => {
    checkLoginInfo()
    nftTransferHistory({}, '1', '10000').then((data) => {
      if (data.success) {
        setRecords(data.result.records)
      }
    })
  }, [])

  const checkLoginInfo = () => {
    const phones = localStorage.getItem('userInfo_phone')
    if (phones) {
      setPhone(phones)
    }
  }
  //前端脱敏

  const desensitize = (phoneNumber) => {
    const prefix = phoneNumber.substring(0, 3) // 前三位
    const suffix = phoneNumber.substring(phoneNumber.length - 4) // 后四位

    return `${prefix}****${suffix}` // 使用星号替换中间的数字
  }

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'转赠记录'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>
      <div style={{ maxHeight: '380px', overflowY: 'scroll' }}>
        <Table>
          <thead>
            <tr>
              <Th textAlign={['center', null, 'left']}> {'类型'}</Th>
              <Th textAlign={['center', null, 'left']}> {'发送人'}</Th>
              <Th textAlign={['center', null, 'left']}> {'接收人'}</Th>
              <Th textAlign={['center', null, 'left']}> {'数量'}</Th>
              <Th textAlign={['center', null, 'left']}> {'日期'}</Th>
            </tr>
          </thead>

          <tbody>
            {records.map((record, inde) => (
              <tr>
                <Td>{record.fromUsername == phone ? '发送' : '接收'}</Td>
                <Td>{desensitize(record.fromUsername)}</Td>
                <Td>{desensitize(record.toUsername)}</Td>
                <Td>{record.amount}</Td>
                <Td>{record.createTime}</Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </StyledModalContainer>
  )
}
export default NftTranferHistory
