


/*提现业务 */
export class WasaiTochain {
  /**主键*/
  public id!: string;
  public createTime!: Date;
  public number!: number;
  /**接收地址*/
  public toAddress!: string;

  public smscode!: string;
}

