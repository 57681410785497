import { SendSms, buyNft, queryTotalNft } from 'hooks/RaidConfig'
import { ToastsProvider, ToastListener } from '../../contexts/ToastsContext'

import { BuyOrder } from 'hooks/modules/BuyOrder'

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Dropdown,
  Image,
  Progress,
  Button,
} from '@pancakeswap/uikit'

import React, { useState, useEffect, Component, useRef } from 'react'

import {
  Switch,
  NavBar,
  Checkbox,
  Radio,
  Input,
  Tabs,
  TabBar,
  Badge,
} from 'antd-mobile'
import useToast from 'hooks/useToast'

import LoginUserInfo from '../common/LoginUserInfo'
import Page from '../common/Page'
import Container from '../common/Container'
import styled from 'styled-components'

import Login from '../lanhu_huaban2/LoginNew'
import CommonHead from '../lanhu_huaban2/CommonHead'

const StyledPage = styled.div`
  min-height: calc(100vh);
  padding-top: 0px;
  padding-bottom: 16px;
  background: url('./assets/img/buybg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  overflow-y: auto;
  overflow-x: hidden;
`

const StyledPageFor = styled.div`
  min-height: 100%;
  padding-top: 0px;
  background: url('./assets/img/buybg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  overflow-y: auto;
`

const BuyBoxBox = styled.div`
  width: 100%;

  margin: auto;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px;
  margin-top: 20px;
  @media screen and (max-width: 968px) {
    width: 100%;
  }
`

const BuyBoxLeft = styled.div`
  width: 50%;

  @media screen and (max-width: 968px) {
    width: 100%;
    padding: 0px;
    transform: scale(0.5, 0.5);
    margin-top: -150px;
  }
`

const BuyBoxLeftText = styled.div`
  padding: 20px;
  width: 100%
  height: 148px;
  font-size: 90px;
  font-family: FZDESHS_512B;
  font-weight: normal;
  font-style: italic;
  color: #ffffff;
  line-height: 79px;
  text-align: center;
  background: linear-gradient(180deg, #3781c7 0%, #ffffff 71.58203125%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 968px) {
    font-size: 75px; 
  }
  
`

const BuyBoxRight = styled.div`
  width: 50%;

  @media screen and (max-width: 968px) {
    width: 100%;
    position: absolute;
    transform: scale(0.5, 0.5);
    top: 250px;
  }
`

const Row = styled.div``
const RowText = styled.div`
  margin: auto;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
`

const StyledCenter = styled.div`
  width: 80%;

  background: url('./assets/img/center.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  overflow-y: auto;
  min-height: 831px;
  margin: auto;
  margin-top: 180px;
  top: 180px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px;
`

const CenterTxt = styled(Text)`
  width: 100%;
  height: 78px;
  font-size: 62px;
  font-family: FZDESHS_512B;
  font-weight: normal;
  font-style: italic;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  @media screen and (max-width: 968px) {
    font-size: 30px;
  }
`
const CenterTxt2 = styled(Text)`
  width: 100%;
  height: 114px;
  font-size: 95px;
  font-family: FZDESHS_512B;
  font-weight: normal;
  font-style: italic;
  color: #ffffff;
  line-height: 36px;
  padding-top: 20px;
  text-align: center;
  margin-top: 35px;
  @media screen and (max-width: 968px) {
    font-size: 35px;
    margin-top: 15px;
  }
`
const CenterTxt3 = styled(Text)`
  width: 100%;
  height: 41px;
  font-size: 42px;
  font-family: PingFangSC;
  font-weight: 500;
  font-style: italic;
  color: #050f2c;
  line-height: 60px;
  padding-top: 20px;
  text-align: center;

  @media screen and (max-width: 968px) {
    font-size: 15px;
  }
`

const StyledCenterCenter = styled.div`
  width: 80%;
  background: url('./assets/img/titel.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  overflow-y: auto;
  min-height: 90px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px;
`

const StyledFoot = styled.div`
  width: 80%;
  overflow-y: auto;
  min-height: 90px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px;
  padding-top: 50px;
`

const RowRightButton = styled(Button)`
  background: url('./assets/img/button2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  margin-left: 30%;
  margin-top: 50px;
  width: 246px;
  height: 85px;
`

interface IndividualNFTPageProps {}

const Main: React.FC = ({}) => {
  const [loginView, setLoginView] = useState(0)

  const [readView, setReadView] = useState(true)

  const [nftNumber, setNftNumber] = useState('1')

  const [progress, setProgress] = useState(1)

  return (
    <>
      <StyledPage>
        <StyledPageFor>
          {loginView >= 1 && (
            <Login setLoginView={setLoginView} typesss={loginView} />
          )}
          <CommonHead setLoginView={setLoginView} loginView={loginView} />
          <BuyBoxBox style={{ minHeight: '462px', marginTop: '120px' }}>
            <Flex>
              <BuyBoxLeft>
                <BuyBoxLeftText style={{ marginTop: '50px' }}>
                  哇塞星球
                </BuyBoxLeftText>
                <BuyBoxLeftText>让梦想成真</BuyBoxLeftText>
                <RowRightButton>
                  <Text fontSize="30px">
                    <a
                      style={{ textDecoration: 'none', color: '#fff' }}
                      href="/"
                    >
                      立即购买
                    </a>
                  </Text>
                </RowRightButton>
              </BuyBoxLeft>
              <BuyBoxRight>
                <Image
                  width={672}
                  height={764}
                  src={'./assets/img/right.png'}
                />
              </BuyBoxRight>
            </Flex>
          </BuyBoxBox>
          <StyledCenter>
            <CenterTxt>五月开启</CenterTxt>
            <CenterTxt2>元宇宙万人大会</CenterTxt2>
            <StyledCenterCenter>
              <CenterTxt3>打造中国数藏第一品牌 开启数藏2.0时代！</CenterTxt3>
            </StyledCenterCenter>
          </StyledCenter>
          <StyledFoot>
            <Row>©2023-2033 重庆哇塞宇宙数字科技有限公司 版权所有</Row>
            <Row style={{ paddingTop: '20px' }}>
              <Flex>
                <Text>联系我们</Text>
                <Text ml={50}>隐私政策</Text>
                <Text ml={50}>用户协议</Text>
              </Flex>
            </Row>
          </StyledFoot>
        </StyledPageFor>
      </StyledPage>
    </>
  )
}

class DDS_lanhupage_0 extends Component {
  render() {
    return (
      <>
        <Main />
      </>
    )
  }
}

export default DDS_lanhupage_0
