import { Dropdown, Image, useMatchBreakpoints, Text } from '@pancakeswap/uikit'

import React, { useEffect, useState } from 'react'

import LoginUserInfo from '../common/LoginUserInfo'
import Menu from '../common/Menu'

import Order from 'view/common/Order'
import TranstsFlow from 'view/common/TranstsFlow'
import UserCardInfo from 'view/common/UserCardInfo'

import styled from 'styled-components'
import BoxGoods from 'view/common/BoxGoods'

const BodyHead = styled.div`
  width: 100%;
  height: 50px;
  background: url(./img/psoad1myimvvez1ejr3eixtryx77cllqpqre0a8930c-50d0-4648-aeda-e9a4733d6fc6.png) -1px -3px
    no-repeat;
  background-size: 100% 53px;
  position: fixed;
  z-index: 10;
`
const Logo = styled(Image)`
  margin: 9px 0 0 80px;

  @media screen and (max-width: 968px) {
    margin: 9px 0 0 10px;
  }
`

const LogoFire = styled(Image)`
  margin: 16px 0 0 56px;

  @media screen and (max-width: 968px) {
    display: none;
  }
`

const Person = styled(Image)`
  position: absolute;
  margin: 0;
  right: 1px;
  top: 8px;
`

const TextSpan = styled.span`
  width: 38px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 17px 0 0 9px;
`

const TextSign = styled.span`
  position: absolute;
  cursor: pointer;
  right: 25px;
  width: 32px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 15px 53px 0 15px;
  @media screen and (max-width: 968px) {
    right: 0px;
  }
`

const TextLogin = styled.span`
  position: absolute;
  cursor: pointer;
  right: 80px;
  width: 32px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 15px 53px 0 15px;

  @media screen and (max-width: 968px) {
    right: 50px;
  }
`
interface ModalProps {
  setLoginView
  loginView
}

const CommonHead: React.FC<ModalProps> = ({ setLoginView, loginView }) => {
  const [phone, setPhone] = useState('')

  const [teamCardView, setTeamCardView] = useState(-1)
  const { isMobile } = useMatchBreakpoints()

  const checkLoginInfo = () => {
    const phones = localStorage.getItem('userInfo_phone')
    if (phones) {
      setPhone(phones)
    }
  }
  /*检查登录状态 */
  useEffect(() => {
    checkLoginInfo()
  }, [loginView])

  /*邀请码 */

  return (
    <>
      <BodyHead>
        <Logo width={119} height={27} src={'./assets/img/logo2x.png'} />

        <LogoFire
          width={15}
          height={18}
          src={
            './assets/img/psok4o390e9oh8o3k39zjfb807lpmhatvmf4351608a4-8adc-4609-889f-e69c5b1a6474.png'
          }
        />
        <TextSpan>
          <a style={{ textDecoration: 'none', color: '#fff' }} href="/">
            预售
          </a>
        </TextSpan>

        <div style={{ position: 'absolute', right: '100px' }}>
          <Dropdown
            target={
              <Person
                width={34}
                height={34}
                src={require('./assets/img/psk82n1rshrhle70g779cxb5am5vx1xlhgl588b3048-4eca-4ed3-b46c-b05f20aaf5cf.png')}
              />
            }
          ></Dropdown>
          {phone && (
            <Text
              style={{
                position: 'absolute',
                right: isMobile ? '-60px' : '-13px',
                paddingTop: '15px',
              }}
            >
              {'**'}
              {phone.replace(/.(?=.{4})/g, '')}
            </Text>
          )}
        </div>

        {teamCardView == 0 && (
          <LoginUserInfo
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 1 && (
          <TranstsFlow
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 2 && (
          <BoxGoods
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 3 && (
          <Order
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 12 && (
          <Order
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}

        {teamCardView == 4 && (
          <UserCardInfo
            onDismiss={() => {
              setTeamCardView(-1)
            }}
          />
        )}
        {!phone && (
          <TextSign
            onClick={() => {
              console.log('login--view')

              setLoginView(1)
            }}
          >
            登录
          </TextSign>
        )}

        {!phone && (
          <TextLogin
            onClick={() => {
              console.log('login--view')
              setLoginView(2)
            }}
          >
            注册
          </TextLogin>
        )}
      </BodyHead>
    </>
  )
}

export default CommonHead
