


/*提现业务 */
export class WasaiTransfer {
  /**id*/
  /**主键*/

  public id!: string;
  /**创建人*/

  public createBy!: string;
  /**创建日期*/

  public createTime!: Date;
  /**更新人*/

  public updateBy!: string;
  /**更新日期*/

  public updateTime!: Date;
  /**所属部门*/

  public sysOrgCode!: string;

  /**发送地址uid*/
  public fromUserid!: string;
  /**接收地址uid*/
  public toUserid!: string;
  /**转赠数量*/
  public amount!: number;
  /**发送地址*/
  public fromUsername!: string;
  /**接收地址*/
  public toUsername!: string;
}

