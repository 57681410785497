


/*用户背包数据 */
export class UserCardInfo {

  /**id*/
  /**主键*/

  public id!: string;



  public uid: string;
  public phone: string;
  public realname: string;
  public bindCard: string;
  public bank: string;
  public bankCode: string;
  public smsCode: string;
  public applyId: string;

  /**卡片类型*/

  public cardType: string;


  /**信用卡*/

  public cardDate!: string;

  /**信用卡*/

  public cardSign!: string;
}

