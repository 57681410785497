
import { API_CONFIG, loginOut } from './RaidConfig';
import { BetOrder } from './modules/BetOrder';
import { Withdraw, WithdrawForm } from './modules/Withdraw';
import { ERRORS } from "./modules/errors";
import { Market } from './modules/Market';
import { UserGoods } from './modules/UserGoods';




/*根据期号 获取历史订单数据 */
export const getUserBetOrderHistory = async (playTpye: string) => {



  // eslint-disable-next-line eqeqeq
  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');

  // eslint-disable-next-line eqeqeq
  if (XAccessToken && (XAccessToken != "") && uid != "") {
    const response = await fetch(API_CONFIG.getUserBetOrderHistory, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        playType: playTpye
      }),
    })

    console.log("response-getUserBetOrderHistory", response);
    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        // 设置token
        const resultList = result.result;
        console.log("response-getUserBetOrderHistory", resultList);
        return resultList;
      }
    }
  }

}





/* 提交提现订单 */
export const submitWithdraw = async (withdraw: WithdrawForm) => {
  console.log("withdraw", JSON.stringify({
    withdraw
  }));

  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');

  // eslint-disable-next-line eqeqeq
  if (XAccessToken && (XAccessToken != "") && uid != "") {
    // 组装数据对象


    const response = await fetch(API_CONFIG.userWithdrawal, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(withdraw),
    })


    if (response.status === 200) {
      const result = await response.json();

      if (result?.success) {
        return { status: 200 }
      }
      return { status: 500, message: result.message.replace("操作失败，") }

    }

    if (response.status === 401) {
      // 登录超时处理办法
      loginOut();
      return { status: 500, message: " Token is invalid, please login again !" }
    }

    return { status: 500, message: "NO Connect!" }
  }
  return { status: 500, message: "NO Login!" }




}







/* 上架商品 */
export const marketAdd = async (market: Market) => {


  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');

  // eslint-disable-next-line eqeqeq
  if (XAccessToken && (XAccessToken != "") && uid != "") {
    // 组装数据对象
    const response = await fetch(API_CONFIG.marketAdd, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(market),
    })

    if (response.status === 200) {
      const result = await response.json();
      if (result?.success) {
        return { status: 200 }
      }
      return { status: 500, message: result.message.replace("操作失败，") }
    }

    if (response.status === 401) {
      // 登录超时处理办法
      loginOut();
      return { status: 500, message: " Token is invalid, please login again !" }
    }
    return { status: 500, message: "NO Connect!" }
  }
  return { status: 500, message: "NO Login!" }




}





/* 购买商品 */
export const marketBuy = async (market: Market) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');

  // eslint-disable-next-line eqeqeq
  if (XAccessToken && (XAccessToken != "") && uid != "") {
    // 组装数据对象


    const response = await fetch(API_CONFIG.marketBuy, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(market),
    })


    if (response.status === 200) {
      const result = await response.json();
      return result;
    }

    if (response.status === 401) {
      // 登录超时处理办法
      loginOut();
      return { status: 500, message: " Token is invalid, please login again !" }
    }

    return { status: 500, message: "NO Connect!" }
  }
  return { status: 500, message: "NO Login!" }

}


/* 取消卖出 */
export const marketExit = async (market: Market) => {

  const XAccessToken = localStorage.getItem('XAccessToken');
  const uid = localStorage.getItem('userInfo_userid');

  // eslint-disable-next-line eqeqeq
  if (XAccessToken && (XAccessToken != "") && uid != "") {
    // 组装数据对象


    const response = await fetch(API_CONFIG.marketExit, {
      method: 'post', headers: {
        'X-Access-Token': XAccessToken,
        'token': XAccessToken,
        'Content-Type': 'application/json',
      }, body: JSON.stringify(market),
    })


    if (response.status === 200) {
      const result = await response.json();
      return result;
    }

    if (response.status === 401) {
      // 登录超时处理办法
      loginOut();
      return { status: 500, message: " Token is invalid, please login again !" }
    }

    return { status: 500, message: "NO Connect!" }
  }
  return { status: 500, message: "NO Login!" }

}


