import { submitWithdraw } from 'hooks/HRaidConfig'
import { num_to_base36, wasaiPerson } from 'hooks/RaidConfig'
import React, { useEffect, useState, useContext } from 'react'
import MyContext from './CommonProvider'

import { userDespoint, WASAI_URL, idCardBind } from 'hooks/RaidConfig'

import { UserInfo } from 'hooks/modules/UserInfo'

import { Deposit } from 'hooks/modules/Deposit'

import styled from 'styled-components'

import {
  Button,
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalTitle,
  Text,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 565px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const BossSonRow = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
  margin-top: 30px;
  margin-left: 15%;
`

const BossSonTopRow = styled.div`
  border-bottom: 1px solid white;
  margin-top: 20px;
  display: flex;
`

const BossRow = styled.div`
  width: 95%;
  height: 150px;
  margin-left: 2.5%;
  background: #ffffff2b;
  margin-top: 40px;
  border-radius: 20px;
  padding: 3px 20px 10px 20px;
`

const BossRowInit = styled.div`
  width: 100%;
  height: 138px;
  font-size: 15px;
  font-weight: normal;
  color: #fff;
  opacity: 0.15;
  line-height: 20px;
`

const BossText = styled(Text)`
  font-size: 24px;
  color: #fff;
  margin-left: 10px;
  font-weight: 600;
  letter-spacing: 4px;
`

const BossInput = styled(Input)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc0;
  outline: none;
  padding: 10px;
  width: 250px;
  font-size: 16px;
  ::placeholder {
    font-size: 14px;
  }
`

interface ModalProps extends InjectedModalProps {}

const LoginRenzheng: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [phone, setPhone] = useState('')

  const [account, setAccount] = useState<any>()

  const [realName, setRealName] = useState('')
  const [idCard, setIdCard] = useState('')
  const { setTeamCardView } = useContext(MyContext)

  const { isMobile } = useMatchBreakpoints()

  const [view, setView] = useState(0)

  const [hashInfo, setHashInfo] = useState('')

  const checkLoginInfo = () => {
    const phones = localStorage.getItem('userInfo_phone')
    if (phones) {
      setPhone(phones)
    }
  }
  /*检查登录状态 */
  useEffect(() => {
    checkLoginInfo()
  }, [])

  const onInputChangeRealName = (e) => {
    setRealName(e.currentTarget.value)
  }

  const onInputChangeIncard = (e) => {
    setIdCard(e.currentTarget.value)
  }

  const bindIdcCardInfo = async () => {
    //绑定卡
    const userInfo = new UserInfo()
    if (realName) {
      userInfo.realname = realName
    } else {
      toastError('请填写姓名！')
      return
    }
    if (idCard) {
      userInfo.f2aId = idCard
    } else {
      toastError('请填写身份证！')
      return
    }

    const result = await idCardBind(userInfo)
    if (result.message) {
      toastError(result.message)
    } else {
      toastSuccess('绑定成功!')
      setTeamCardView(5)
    }
  }

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'实名认证'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>
      <BossSonTopRow>
        <BossText>真实姓名:</BossText>
        <BossInput
          onChange={onInputChangeRealName}
          value={realName}
          placeholder="请输入真实姓名"
        />
      </BossSonTopRow>
      <BossSonTopRow>
        <BossText>身份证号:</BossText>
        <BossInput
          onChange={onInputChangeIncard}
          value={idCard}
          placeholder="请输入身份证号"
        />
      </BossSonTopRow>
      <BossSonTopRow>
        <BossText>联系方式:</BossText>
        <BossInput
          onChange={() => {
            return
          }}
          value={phone}
          placeholder="188****8888"
        />
      </BossSonTopRow>
      <BossRow>
        <BossRowInit>
          <p>
            根据法律法规要求，实名信息须与注册手机号持有人相符，且年满
            18周岁（不含）以上，60周岁（含）以下。
          </p>
          <p>
            信息安全保障中，以下信息仅用于身份确认，未经您同意不会用于
            其它用途。
          </p>
        </BossRowInit>
      </BossRow>
      <BossSonRow>
        <Button
          style={{ width: '160px', background: 'rgb(211 49 187)' }}
          onClick={bindIdcCardInfo}
        >
          立即认证
        </Button>
      </BossSonRow>
    </StyledModalContainer>
  )
}
export default LoginRenzheng
