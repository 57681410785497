import { submitWithdraw } from 'hooks/HRaidConfig'
import { num_to_base36, wasaiPerson } from 'hooks/RaidConfig'
import React, { useEffect, useState, useContext, useRef } from 'react'
import MyContext from './CommonProvider'

import { fastPayConfirm, WASAI_URL } from 'hooks/RaidConfig'

import { BuyOrder } from 'hooks/modules/BuyOrder'

import { Deposit } from 'hooks/modules/Deposit'
import PayConfirm from '../lanhu_huaban2/PayConfirm'

import styled from 'styled-components'

import {
  Button,
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalTitle,
  Text,
  useMatchBreakpoints,
  Image,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 500px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 310;
  float: left;
`

const BossSonRow = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: #fff;
  margin-top: 10px;
`
const BossSonRow2 = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: #fff;
  margin-top: 40px;
`


const BankCardCard = styled.div`
  background: url('./assets/img/bank2x.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 90%;
  height: 70px;
  display: flex;
  border-radius: 10px;

  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 30px;
`

const BossBankText = styled(Text)`
  font-size: 16px;
  color: #fff;
  margin-left: 20px;
  margin-top: 16px;
  font-weight: 400;
  letter-spacing: 4px;
  padding-top: 8px;
  width: 240px;
`

const CardBody = styled.div`
  height: 330px;
  overflow-y: auto;
`
interface ModalProps {
  onDismiss: (string) => void
}

const FastPaySelectCard: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()

  const [selected, setSelected] = useState('')
  const [userCardInfoList, setUserCardInfoList] = useState<any[]>([])

  const { teamCardView, setTeamCardView } = useContext(MyContext)

  useEffect(() => {
    queryData()
  }, [])

  const queryData = async () => {
    const result = await wasaiPerson()
    if (result?.result) {
      setUserCardInfoList(result?.result?.userCardList)
      if (result?.result?.userCardList.length > 0) {
        setSelected(result?.result?.userCardList[0]?.bindCard)
      }
    }
  }

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'选择银行卡'}</Heading>
          </ModalTitle>
          <IconButton
            variant="text"
            onClick={() => {
              onDismiss('')
            }}
          >
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>


        
      {/* {userCardInfoList&& <TextRow>添加银行卡</TextRow>} */}
      <CardBody>

         {userCardInfoList.length==0 && <BossSonRow2>
            <Button
              style={{ width: '180px', background: 'rgb(211 49 187)' }}
              onClick={() => {
                onDismiss('bindcard')
                setTeamCardView(8)
              }}
            >
            点击绑定银行卡
          </Button>
        </BossSonRow2>}

        {userCardInfoList?.map((val) => (
          <BankCardCard
            onClick={() => {
              setSelected(val.bindCard)
            }}
          >
            {selected == val.bindCard && (
              <Image
                marginTop={25}
                marginLeft={20}
                width={20}
                height={20}
                src={'./assets/img/selected.png'}
              />
            )}
            {selected != val.bindCard && (
              <Image
                marginTop={25}
                marginLeft={20}
                width={20}
                height={20}
                src={'./assets/img/select.png'}
              />
            )}

            <BossBankText>
              {val.bank + (val.cardType == 'CC' ? '信用卡' : '储蓄卡')}{' '}
              {'(' + val.bindCard.slice(-4) + ')'}
            </BossBankText>
          </BankCardCard>
        ))}
      </CardBody>
      <div
        style={{
          display: 'flex',
          marginTop: '15px',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <BossSonRow>
          <Button
            style={{ width: '160px', background: 'rgb(211 49 187)' }}
            onClick={() => {
              onDismiss(selected)
            }}
          >
            确认付款
          </Button>
        </BossSonRow>
      </div>
    </StyledModalContainer>
  )
}
export default FastPaySelectCard
