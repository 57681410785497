import React, { useState, useEffect, Component } from 'react'
import {
  SendSms,
  base36_to_num,
  num_to_base36,
  buyOrderList,
} from 'hooks/RaidConfig'

import styled from 'styled-components'
import { loginOut } from 'hooks/RaidConfig'
import { CopyButton } from './CopyButton'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Button,
  InjectedModalProps,
  CloseIcon,
  ModalTitle,
  IconButton,
  Table,
  Th,
  Td,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
const InfoBody = styled.div`
  width: 300px;
  min-width: 300px;
  height: 300px;
`

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 465px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const Wrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dropdown};
  border-radius: 16px;
  position: relative;
`

const Address = styled.div`
  flex: 1;
  position: relative;
  padding-left: 16px;

  & > input {
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
`

const BossSonRow = styled.div`
  border: dashed 1px #000;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface ModalProps extends InjectedModalProps {}

const Order: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [inviteLink, setInviteLink] = useState('')
  const [records, setRecords] = useState([])

  useEffect(() => {
    buyOrderList({}, '1', '10000').then((data) => {
      if (data.success) {
        setRecords(data.result.records)
      }
    })
  }, [])

  //查询账户余额

  //查询盒子信息

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'购买记录'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>
      <div style={{ maxHeight: '380px', overflowY: 'scroll' }}>
        <Table>
          <thead>
            <tr>
              <Th textAlign={['center', null, 'left']}> {'ID'}</Th>
              <Th textAlign={['center', null, 'left']}> {'购买数量'}</Th>
              <Th textAlign={['center', null, 'left']}> {'金额'}</Th>
              <Th textAlign={['center', null, 'left']}> {'日期'}</Th>
              <Th textAlign={['center', null, 'left']}> {'状态'}</Th>
              <Th textAlign={['center', null, 'left']}> {'操作'}</Th>
            </tr>
          </thead>

          <tbody>
            {records.map((record, inde) => (
              <tr>
                <Td>{inde + 1}</Td>
                <Td>{record.buyNumber}</Td>
                <Td>{record.payNumber}</Td>
                <Td>{record.createTime}</Td>
                <Td>
                  {record.status == 0
                    ? '待支付'
                    : record.status == 1
                    ? '已支付'
                    : record.status == 2
                    ? '已取消'
                    : ''}
                </Td>
                <Td>
                  {record.status == 0 && (
                    <a target="_blank" href={record.payUrl}>
                      支付
                    </a>
                  )}
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </StyledModalContainer>
  )
}
export default Order
