import React, { createContext, useCallback, useState } from 'react'

type PersonProps = {
  children: React.ReactNode // 添加children类型
}
const MyContext = createContext(undefined)

export const CommonProvider: React.FC<PersonProps> = ({ children }) => {
  const [teamCardView, setTeamCardView] = useState(-1)
  //1632993087753932801

  const [payConfrimView, setPayConfrimView] = useState(
    localStorage.getItem('payConfrim')
    //'1632993087753932801'
  )
  //1186738177
  const [payDespointConfrimView, setPayDespointConfrimView] = useState(
    localStorage.getItem('payDespointConfrim')
  )
  return (
    <MyContext.Provider
      value={{
        payConfrimView,
        setPayConfrimView,
        teamCardView,
        setTeamCardView,
        payDespointConfrimView,
        setPayDespointConfrimView,
      }}
    >
      {children}
    </MyContext.Provider>
  )
}

export default MyContext
