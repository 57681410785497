


/*提现业务 */
export class Deposit {
  /**id*/

  public id!: number;
  /**用户id*/

  public uid!: string;
  /**用户名*/

  public username!: string;
  /**加密货币代号，大写字母 BTC，LTC，ETH*/

  public coin!: string;

  /**批次号*/

  public batchNo!: number;
  /**提现金额*/

  public amount!: number;


  public isMining!: number;

  /**payUrl/smscode*/

  public payUrl!: string;

  /**银行卡号*/

  public bindCard!: string;

  /**银行名称e*/

  public bank!: string;
}

