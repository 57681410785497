import { num_to_base36, WASAI_URL } from 'hooks/RaidConfig'
import React, { useContext, useEffect, useState } from 'react'

import { loginOut } from 'hooks/RaidConfig'
import styled from 'styled-components'
import MyContext from './CommonProvider'
import {
  Button,
  InjectedModalProps,
  ArrowDropDownIcon,
  ArrowDropUpIcon,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
const InfoBody = styled.div`
  width: 160px;
  min-width: 160px;
  position: fixed;
  top: 40px;
  right: -20px;
  background: #232222b5;
  border-radius: 5px;
`

const BossSonRow = styled.div`
  width: 100%;
  padding: 3px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  color: #fff;
`

const BossSonRowChild = styled.div`
  width: 100%;
  padding: 3px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  color: #fff;
`

const BossSonMenuRow = styled.div`
  width: 100%;
  padding: 3px 0px;
  display: flex;
  height: 50px;
  color: #fff;
`

const ButtonEmpty = styled(Button)<{ active: boolean }>`
  border-radius: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0px;
  font-size: 18px;
  width: 130px;
  height: 41px;
  margin-bottom: 0px;

  &:disabled {
    background-color: transparent;
  }
  opacity: ${({ active }) => (!active ? '0.65' : '1')};

  &:hover {
    opacity: ${({ active }) => (active ? '1' : '0.65')}!important;
  }
`

interface ModalProps extends InjectedModalProps {
  onDismiss: () => void
  setMenuView: (u: any) => void
}

const Menu: React.FC<ModalProps> = ({ onDismiss, setMenuView }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [inviteLink, setInviteLink] = useState('')

  const [showHidden, setShowHidden] = useState(0)

  const { teamCardView, setTeamCardView } = useContext(MyContext)
  useEffect(() => {
    let myinvite = localStorage.getItem('myinvite')

    if (myinvite) {
      setInviteLink(
        WASAI_URL + '/lanhu_huaban2?invite=' + num_to_base36(myinvite)
      )
    }
  }, [])

  const handleLogout = () => {
    loginOut()
    onDismiss()
    location.href = WASAI_URL
    toastSuccess('Success', '退出成功'!)
  }

  //查询账户余额

  //查询盒子信息

  return (
    <InfoBody
      onMouseLeave={() => {
        setMenuView(false)
      }}
    >
      <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(0)
          }}
        >
          分享推广
        </ButtonEmpty>
      </BossSonRow>
      <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(11)
          }}
        >
          藏品转赠
        </ButtonEmpty>
      </BossSonRow>
      <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(14)
          }}
        >
          藏品提取
        </ButtonEmpty>
      </BossSonRow>
      <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(5)
          }}
        >
          钱包信息
        </ButtonEmpty>
      </BossSonRow>
      {/*   <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(7)
          }}
        >
          充值绑定
        </ButtonEmpty>
      </BossSonRow>
      <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(4)
          }}
        >
          提现绑定
        </ButtonEmpty>
      </BossSonRow> */}

      <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(8)
          }}
        >
          银行卡包
        </ButtonEmpty>
      </BossSonRow>
      <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(1)
          }}
        >
          账户流水
        </ButtonEmpty>
      </BossSonRow>
      <BossSonRow>
        <ButtonEmpty
          onClick={() => {
            setTeamCardView(2)
          }}
        >
          藏品信息
        </ButtonEmpty>
      </BossSonRow>
      <BossSonMenuRow>
        {showHidden == 0 && <ArrowDropDownIcon />}
        {showHidden == 1 && <ArrowDropUpIcon />}

        <ButtonEmpty
          onClick={() => {
            if (showHidden == 1) {
              setShowHidden(0)
            } else {
              setShowHidden(1)
            }
          }}
        >
          历史记录
        </ButtonEmpty>
      </BossSonMenuRow>

      {showHidden == 1 && (
        <>
          <BossSonRowChild>
            <ButtonEmpty
              onClick={() => {
                setTeamCardView(3)
              }}
            >
              购买记录
            </ButtonEmpty>
          </BossSonRowChild>
          <BossSonRowChild>
            <ButtonEmpty
              onClick={() => {
                setTeamCardView(12)
              }}
            >
              转赠记录
            </ButtonEmpty>
          </BossSonRowChild>
        </>
      )}

      <BossSonRow>
        <ButtonEmpty onClick={handleLogout}>退出登录</ButtonEmpty>
      </BossSonRow>
    </InfoBody>
  )
}
export default Menu
