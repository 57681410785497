import React, { useState, useEffect, Component } from 'react'
import {
  SendSms,
  base36_to_num,
  num_to_base36,
  queryBagByType,
} from 'hooks/RaidConfig'

import styled from 'styled-components'
import { loginOut } from 'hooks/RaidConfig'
import { CopyButton } from './CopyButton'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Button,
  InjectedModalProps,
  CloseIcon,
  ModalTitle,
  IconButton,
  Table,
  Th,
  Td,
  Image,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
const InfoBody = styled.div`
  width: 300px;
  min-width: 300px;
  height: 300px;
`

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 465px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const Wrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dropdown};
  border-radius: 16px;
  position: relative;
`

const Address = styled.div`
  flex: 1;
  position: relative;
  padding-left: 16px;

  & > input {
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
`

const BossSonRow = styled.div`
  border: dashed 1px #000;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface ModalProps extends InjectedModalProps {}

const BoxGoods: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [inviteLink, setInviteLink] = useState('')
  const [records, setRecords] = useState([])

  useEffect(() => {
    queryBagByType('Blind Box', '').then((heroes) => {
      setRecords(heroes)
    })
  }, [])

  //查询账户余额

  //查询盒子信息

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Flex>
              <Heading>{'藏品信息'}</Heading>
              <Heading ml={5}>数量：{records.length}</Heading>
            </Flex>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>
      <div style={{ maxHeight: '380px', overflowY: 'scroll' }}>
        <Table>
          <thead>
            <tr>
              <Th textAlign={['center', null, 'left']}> {'ID'}</Th>
              <Th textAlign={['center', null, 'left']}> {'图片'}</Th>
              <Th textAlign={['center', null, 'left']}> {'藏品名称'}</Th>
              <Th textAlign={['center', null, 'left']}> {'链上信息'}</Th>
            </tr>
          </thead>

          <tbody>
            {records.map((record, idex) => (
              <tr>
                <Td>{idex + 1}</Td>
                <Td>
                  <Image width={50} height={50} src={record.goodsUrl} />
                </Td>

                <Td>{record.goodsName}</Td>

                <Td>
                  <a
                    target={'_blank'}
                    href={
                      'https://evm.confluxscan.net/nft/0xd8f5aa267d3a376afa7cb16729382e4d3cc6b2e4/' +
                      record.nftId
                    }
                  >
                    查看
                  </a>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </StyledModalContainer>
  )
}
export default BoxGoods
