import {
  Button,
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalTitle,
  Text,
  Image,
  ArrowDropUpIcon,
} from '@pancakeswap/uikit'
import { nftTochain,SendSms } from 'hooks/RaidConfig'
import { WasaiTochain } from 'hooks/modules/WasaiTochain'
import useToast from 'hooks/useToast'
import React, { useState, useEffect, Component, useRef } from 'react'

import styled from 'styled-components'
 

import { SmsInfo } from 'hooks/modules/SmsInfo'
const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 450px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const BossSonRow = styled.div`
  width: 100%;
  padding: 3px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  color: #fff;
`

const BossSonTopRow = styled.div`
  border-bottom: 1px solid white;

  display: flex;
  width: 220px;
  height: 42.5px;
  background: #ffffff;
  border-radius: 15px;
  margin: 0 7.5% 0;
`

const BossInput = styled(Input)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc0;
  outline: none;
  padding: 10px;
  width: 220px;
  font-size: 18px;
  color: #000;
  ::placeholder {
    font-size: 14px;
  }
`

interface ModalProps extends InjectedModalProps {}

const WasaiTochainPage: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()

  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')

  const [amount, setAmount] = useState()

  const [view, setView] = useState('')

 
  
  const [time, setTime] = useState(0) //倒计时时间

  const timeRef = useRef(null) //设置延时器


  //查询账户余额

  //用户提现
  const handleWasaiTransfer = async () => {
    const wasaiTochain = new WasaiTochain()

    wasaiTochain.toAddress = phone
    wasaiTochain.number = amount
    wasaiTochain.smscode = code
    const resp = await nftTochain(wasaiTochain)
    if (resp?.message) {
      setView(resp?.message.replace('Error,', ''))
    } else {
      setView('提取成功!')
    }
  }

  const onInputChangeIncard = (e) => {
    setPhone(e.currentTarget.value)
  }

  const onInputChangeIncardCode = (e) => {
    setCode(e.currentTarget.value)
  }

  const onInputChange = (e) => {
    setAmount(e.currentTarget.value.replace(/[^\d]/g, ''))
  }


    //倒计时 end
  //获取验证码
  const getSms = async () => {
    const phones = localStorage.getItem('userInfo_phone')
    console.log('123123123', 123123123)
    const smsInfo = new SmsInfo()

    const regex = /^1[3456789]\d{9}$/ //使用此正则即可
    if (!regex.test(phones)) {
      toastError('Error', '手机格式错误!')
      return
    }
    smsInfo.mobile = phones
    smsInfo.smsmode = '0'
    const result = await SendSms(smsInfo)

    if (result?.success) {
      toastSuccess('Success', '短信发送成功！')
      setTime(60)
    } else if (result?.message) {
      toastError('Error', result?.message)
      setTime(60)
    }

    console.log('result', result)
  }


  useEffect(() => {
    //如果设置倒计时且倒计时不为0
    if (time && time !== 0)
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1)
      }, 1000)
    //清楚延时器
    return () => {
      clearTimeout(timeRef.current)
    }
  }, [time])

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'藏品提取'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>

      {view && (
        <>
          <BossSonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '100px',
            }}
          >
            <Text fontSize="25px" color="#fff" textAlign={'center'}>
              {view}
            </Text>
          </BossSonRow>

          <BossSonRow
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {view == '提取成功!' && (
              <Image
                onClick={() => {
                  setView('')
                }}
                width={50}
                height={50}
                src={'./assets/img/gougou.png'}
              />
            )}
            {view != '提取成功!' && (
              <Image
                onClick={() => {
                  setView('')
                }}
                width={50}
                height={50}
                src={'./assets/img/xx.png'}
              />
            )}
          </BossSonRow>
        </>
      )}

      {!view && (
        <>
        
          <BossSonRow>
            <Text width={70}>藏品数量:</Text>{' '}
            <BossSonTopRow>
              <BossInput
                onChange={onInputChange}
                placeholder="请输入提取数量"
                value={amount}
              />{' '}
            </BossSonTopRow>
          </BossSonRow>


          <BossSonRow>
            <Text width={70}>地址: </Text>
            <BossSonTopRow>
              <BossInput
                onChange={onInputChangeIncard}
                placeholder="请输入以太坊地址"
                value={phone}
              />
            </BossSonTopRow>
          </BossSonRow>

          
          <BossSonRow>
            <Text width={70}>验证码: </Text>
            <BossSonTopRow>
              <BossInput
                onChange={onInputChangeIncardCode}
                placeholder="请输入手机验证码"
                value={code}
              />
             
            </BossSonTopRow>
            <Text
                    ml={-80}  
                    p={10}
                    style={{paddingRight:"30px"}}
                    textAlign={"left"}
                    color={'#77ACEC'}
                    onClick={() => {
                      getSms()
                    }}
                  >
                    {time > 1 && time}
                    {time < 1 && '获取'}
                  </Text>
          </BossSonRow>

          <BossSonRow>
            <Button style={{ width: '130px' }} onClick={onDismiss}>
              取消
            </Button>
            <Button
              onClick={handleWasaiTransfer}
              style={{ width: '130px', background: 'rgb(211 49 187)' }}
            >
              确认
            </Button>
          </BossSonRow>
        </>
      )}
    </StyledModalContainer>
  )
}
export default WasaiTochainPage
