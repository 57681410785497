import { submitWithdraw } from 'hooks/HRaidConfig'
import { num_to_base36, wasaiPerson } from 'hooks/RaidConfig'
import React, { useEffect, useState, useContext } from 'react'
import MyContext from './CommonProvider'

import CopyAddress from './CopyAddress'

import { userDespoint, WASAI_URL } from 'hooks/RaidConfig'

import { Deposit } from 'hooks/modules/Deposit'

import styled from 'styled-components'
import FastPayConfirmDeposit from '../common/FastPayConfirmDeposit'
import FastPaySelectCard from '../common/FastPaySelectCard'

import {
  Button,
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalTitle,
  Text,
  useMatchBreakpoints,
  Image,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'

const FixBody = styled.div`
  width: 400px;
  min-width: 300px;
  height: 320px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  position: fixed;
  z-index: 301;
  background-color: #000;
  border-radius: 30px;
  border: dashed 1px #2c2a2a;
  box-shadow: 0 0 8px rgba(241, 39, 232, 0.5);
`

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 580px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const BossSonRow = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff; 
`

const BossSonFirstRow = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  margin-top: 50px;
  display: block;
  justify-content: space-between;
 
  height: 60px;
  color: #fff;
  text-align: left; 
`

const BossSonFirstRowTex = styled.div` 
background: linear-gradient(90deg,#fcb63c75,#ffffff00 50% );
    width: 150px;
    height: 25px;
    margin-left: 35px;
    padding: 4px;
    padding-left: 6px;
`

const BossSonBigRow = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  display: block;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: 700;
  height: 60px;
  color: #fff;
  font-size: 60px;
  text-align: center;
  align-items: center;
`
const BossSonNoRowTop = styled.div`
  width: 92%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
  margin: 0 2.5% 0;
`

const BossSonNoRow = styled.div`
  width: 87.5%; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
  margin: 0 7.5% 0;
`

const BossSonBootmRow = styled.div`
  width: 87.5%;  
  align-items: center;
  height: 60px;
  color: #fff;
  margin-top:15px;
`


const RowCenter = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 87.5%;
  height: 34px;
  margin: 0 5% 0;
`

const DivPayRaidoOut = styled.div`
  margin-left: 2.5%;
  margin-top: 6px;
  appearance: none; /* 隐藏默认样式 */

  border: 2px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  cursor: pointer;
`

const DivPayRaido = styled.input`
  appearance: none; /* 隐藏默认样式 */
  border-radius: 50%;
  margin-left: 2.8px;
  margin-top: 3.2px;

  width: 10px;
  height: 10px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  /* 当选中时，修改背景颜色和边框颜色为蓝色 */
  :checked {
    background-color: #00ffff;
    border-color: #00ffff;
  }
`

const DivPay = styled.div`
  width: 40%;
  display: flex;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 3px;
  padding-top: 1px;
  margin-left: 2.5%;
  font-size: 12px;
`

const BossInputW = styled(Input)`
  text-align: center;
  ::placeholder {
    text-align: center;
  }
`

const BossInput = styled(Input)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc0;
  outline: none;
  padding: 10px;
  width: 250px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  text-align: center;
  ::placeholder {
    font-size: 14px;
    font-weight: 600;

    text-align: center;
  }
`

const BossSonTopRow = styled.div`
  border-bottom: 1px solid white;

  display: flex;
  width: 85%;
  height: 42.5px;
  background: #ffffff;
  border-radius: 15px;
  margin: 0 7.5% 0;
`

interface ModalProps extends InjectedModalProps {}

const LoginAccountInfo: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [inviteLink, setInviteLink] = useState('')
  const [inviteCodd, setInviteCodd] = useState('')

  const [account, setAccount] = useState<any>()
  const [admin, setAdmin] = useState<any>()
  const [wasaiInvite, setWasaiInvite] = useState<any>()
  const [hashAddress, setHashAddress] = useState<any>()
  const [userInfo, setUserInfo] = useState<any>()

  const [withdrawAmount, setWithdrawAmount] = useState('')

  const [despointAmount, setDespointAmount] = useState('')

  const { isMobile } = useMatchBreakpoints()

  const { setPayDespointConfrimView, setTeamCardView } = useContext(MyContext)
  const [isloadding, setIsloadding] = useState(false)

  const [view, setView] = useState(0)

  const [wasaiInviteChilds, setWasaiInviteChilds] = useState<any>([])
  const [radio, setRadio] = useState('fastpay')
  const [depositId, setDepositId] = useState(0)
  const [cardSelectView, setCardSelectView] = useState(false)

  const queryData = async () => {
    const result = await wasaiPerson()

    if (result?.result) {
      setAccount(result?.result?.account)
      setAdmin(result?.result?.Admin)
      setWasaiInvite(result?.result?.wasaiInvite)
      setWasaiInviteChilds(result?.result?.wasaiInviteChilds)

      setHashAddress(result?.result?.HashAddress)
      setUserInfo(result?.result?.userInfo)
    }
    setIsloadding(true)
  }

  useEffect(() => {
    let myinvite = localStorage.getItem('myinvite')

    if (myinvite) {
      setInviteLink(WASAI_URL + '?invite=' + num_to_base36(myinvite))
      setInviteCodd(num_to_base36(myinvite))
    }
    queryData()
  }, [])

  //查询账户余额

  //用户提现
  const handleWithdraw = async () => {
    const payload = {
      coin: 'RMB',
      tokenBase: 'BSC',
      amount: parseFloat(withdrawAmount),
      addressTo: '',
    }

    const resp = await submitWithdraw(payload)
    if (resp.status === 200) {
      toastSuccess('提交成功！')
      setView(0)
      queryData()
    } else {
      toastError(resp?.message)
    }
  }

  //用户充值
  const handleDespoint = async (cardId) => {
    const deposit = new Deposit()

    if (radio == 'fastpay') {
      deposit.isMining = 1
      deposit.bindCard = cardId
    }

    if (!despointAmount) {
      toastError('请输入数量！')
      return
    }
    deposit.amount = parseFloat(despointAmount)

    const resp = await userDespoint(deposit)
    if (resp?.success) {
      toastSuccess('提交成功！')
      if (resp?.result?.deposit?.isMining == 1) {
        setDepositId(resp?.result?.deposit?.id)
      } else {
        setView(0)
        //支付成功赠加确认界面
        setPayDespointConfrimView(resp?.result?.deposit?.id)
        localStorage.setItem('payDespointConfrim', resp?.result?.deposit?.id)
        if (isMobile) {
          setTimeout(() => {
            location.href = resp?.result?.deposit?.payUrl
          }, 1000)
        } else {
          //pc支付
          location.href =
            '#/PayCardPc?t=' +
            resp?.result?.deposit?.payUrl +
            '&p=' +
            resp?.result?.deposit?.amount
        }
      }
    } else {
      toastError(resp?.message)
    }
  }

  const onInputChange = (e) => {
    setWithdrawAmount(e.currentTarget.value.replace(/[^\d]/g, ''))
  }

  const onInputChangeDespoint = (e) => {
    setDespointAmount(e.currentTarget.value.replace(/[^\d]/g, ''))
  }

  const handleChange = (evt) => {
    console.info('fired')
    const { value } = evt.target
    setRadio(value)
  }

  return (
    <StyledModalContainer>
      {cardSelectView && (
        <FastPaySelectCard
          onDismiss={(cardId: string) => {
            setCardSelectView(false)
            if (cardId) {
              handleDespoint(cardId)
            }
          }}
        />
      )}

      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'钱包信息'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>

      <Image width={1} height={1} src={'./assets/img/box3.png'} />

      <Image width={1} height={1} src={'./assets/img/top.png'} />
      <BossSonFirstRow><BossSonFirstRowTex>我的余额（元）</BossSonFirstRowTex></BossSonFirstRow>
      <BossSonBigRow>￥{account?.normalBalance.toFixed(2)} </BossSonBigRow>

      <BossSonRow>
        <Button style={{ width: '110px' }} onClick={() => setView(1)}>
          充值
        </Button>
        <Button
          style={{ width: '110px', background: 'rgb(211 49 187)' }}
          onClick={() => setView(2)}
        >
          提现
        </Button>
      </BossSonRow>


      <BossSonBootmRow>
      <BossSonFirstRowTex  style={{marginTop:"5px",marginLeft:"11.5%"}}  > 
         树图链钱包地址：
      </BossSonFirstRowTex>
        <CopyAddress style={{marginLeft:"11.5%",marginTop:"15px"}} account={hashAddress?.address} accountSim ={hashAddress?.address} />{' '}
      </BossSonBootmRow>

      {
        <div
          style={{
            display: 'flex',
            marginTop: '50px',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          {isloadding && !userInfo?.f2aId && (
            <>
              <Text textAlign={'center'} color="#FF0101">
                {'提示：您当前未实名认证'}
              </Text>
              <Text
                ml={1}
                textAlign={'center'}
                color="#7293FF"
                onClick={() => {
                  setTeamCardView(6)
                }}
              >
                {' 前往认证>>'}
              </Text>
            </>
          )}

          {isloadding && userInfo?.f2aId && (
            <>
              <Image width={20} height={20} src={'./assets/img/gui.png'} />
              <Text ml={1} textAlign={'center'} color="#D331BB">
                {'您已完成实名认证'}
              </Text>
            </>
          )}
        </div>
      }

     
    

      {view == 1 && (
        <FixBody>
          {depositId != 0 && (
            <FastPayConfirmDeposit
              depositId={depositId}
              onDismiss={() => {
                setDepositId(0)
              }}
            />
          )}
          <BossSonNoRowTop>
            <Text fontSize={'16px'} fontWeight={600}>
              充值
            </Text>
          </BossSonNoRowTop>

          <BossSonTopRow>
            <BossInput
              style={{ width: '100%', left: '-30px' }}
              onChange={onInputChangeDespoint}
              value={despointAmount}
              placeholder="请输入您要充值的金额"
            />
          </BossSonTopRow>
          <RowCenter
            style={{ marginTop: '40px', display: 'flex', height: '35px' }}
          >
            <DivPayRaidoOut>
              <DivPayRaido
                type={'radio'}
                name="md"
                width={10}
                height={10}
                value="fastpay"
                onChange={handleChange}
                checked={radio === 'fastpay'}
              />
            </DivPayRaidoOut>
            <DivPay
              onClick={() => {
                setRadio('fastpay')
              }}
            >
              <Image
                style={{ marginTop: '2px' }}
                width={40}
                height={25}
                src={'./assets/img/pay1.png'}
              />
              <Text style={{ marginTop: '3px' }} ml={1} mr={1}>
                银行卡支付
              </Text>
            </DivPay>

            <DivPayRaidoOut>
              <DivPayRaido
                type={'radio'}
                name="md"
                value="alipay"
                onChange={handleChange}
                checked={radio === 'alipay'}
              />{' '}
            </DivPayRaidoOut>
            <DivPay
              onClick={() => {
                setRadio('alipay')
              }}
            >
              <Image
                style={{ marginTop: '2px' }}
                width={25}
                height={25}
                src={'./assets/img/zhifubao.png'}
              />
              <Text style={{ marginTop: '3px' }} ml={1} mt={1}>
                支付宝支付
              </Text>{' '}
            </DivPay>
          </RowCenter>
          <BossSonNoRow style={{ marginTop: '30px' }}>
            <Button style={{ width: '160px' }} onClick={() => setView(0)}>
              取消
            </Button>
            <Button
              style={{ width: '160px', background: 'rgb(211 49 187)' }}
              onClick={() => {
                if (radio == 'fastpay') {
                  setCardSelectView(true)
                } else {
                  handleDespoint('')
                }
              }}
            >
              充值
            </Button>
          </BossSonNoRow>
        </FixBody>
      )}

      {view == 2 && (
        <FixBody>
          <BossSonNoRow>
            <Text fontSize="20px"> 提现</Text>
          </BossSonNoRow>

          <BossSonNoRow style={{ marginTop: '20px' }}>
            <BossInputW
              style={{ width: '100%', left: '-30px' }}
              onChange={onInputChange}
              value={withdrawAmount}
              placeholder="请输入你要提现的金额"
            />
          </BossSonNoRow>
          <BossSonNoRow style={{ marginTop: '20px' }}>
            <Button style={{ width: '130px' }} onClick={() => setView(0)}>
              取消
            </Button>
            <Button
              style={{ width: '130px', background: 'rgb(211 49 187)' }}
              onClick={handleWithdraw}
            >
              提现
            </Button>
          </BossSonNoRow>
        </FixBody>
      )}
    </StyledModalContainer>
  )
}
export default LoginAccountInfo
