import { SendSms, phoneLogin, base36_to_num, WASAI_URL } from 'hooks/RaidConfig'
import { ToastsProvider, ToastListener } from '../../contexts/ToastsContext'

import { SmsInfo } from 'hooks/modules/SmsInfo'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Dropdown,
  InjectedModalProps,
  Image,
  CloseIcon,
  Button,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'

import React, { useState, useEffect, Component, useRef } from 'react'
import styled from 'styled-components'

import {
  Switch,
  NavBar,
  Checkbox,
  Radio,
  Input,
  Tabs,
  TabBar,
  Badge,
} from 'antd-mobile'
import useToast from 'hooks/useToast'

import LoginUserInfo from '../common/LoginUserInfo'

const MaxPage = styled.div`
  position: fixed;
  background: #505050c7;
  width: 100vw;
  height: 100vh;
  z-index: 300;
`

const StyledModalContainer = styled.div`
  position: fixed;

  background: url('./assets/img/box2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 30px;
  width: 834px;
  height: 483px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;

  @media screen and (max-width: 968px) {
    padding: 0px;
    background: url('./assets/img/box.png');
  }
`

const Row = styled.div`
  width: 100%;
`
const RowAbs = styled.div`
  width: 80px;
  position:absolute;
  left: 20px;
  bottom: 80px;
`
const RowInput = styled.div`
  padding: 13px;
  padding-left: 25px;
  width: 100%;
  background: url('./assets/img/input.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 269px;
  height: 65px;
  display: flex;
`

const RowRight = styled.div<{ typess: number }>`
  width: 342px;
  height: 344px;
  margin-top: 80px;
  margin-left: 40px;
  background: url('./assets/img/log3.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  padding-top: ${({ typess }) => (typess == 1 ? `120px` : `80px`)};
  padding-left: 30px;
  min-height: 364px;
  @media screen and (max-width: 968px) {
    width: 95%;
    height: 0px;
    margin-left: 2%;
    margin-top: 0px;
    padding-left: 40px;
    padding-top: ${({ typess }) => (typess == 1 ? `110px` : `80px`)};
  }
`
const RowRightButton = styled(Button)`
  width: 55%;
  background: url('./assets/img/button.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 269px;
  height: 45px;
`

const RowRightInput = styled(Input)`
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #000;
  font-size: 20px;
  height: 40px;
`

const RowCenter = styled.div`
  background: url('./assets/img/kuang.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
`

const PhoneDiv = styled.div`
  width: 50%;
  display: flex;
  @media screen and (max-width: 968px) {
    display: none;
  }
`

const Tooltip = styled.div<{ viewTip: boolean }>`
  color: red;
  display: inline-block;
  background: url('./assets/img/qipao.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 120px;
  height: 30px;
  font-size: 12px;
  font-weight: 700;
  padding-top: 3px;
  padding-left: 3px;

  ${({ viewTip }) =>
    viewTip &&
    `
     transition: visibility 0s, opacity 0.6s linear;
    `}
`

interface ModalProps extends InjectedModalProps {
  setLoginView: (u: any) => void
  typesss:number
}

const Login: React.FC<ModalProps> = ({ setLoginView, typesss }) => {
  const [phone, setPhone] = useState('')
  const [yzm, setYzm] = useState('')
  const [yqm, setYqm] = useState('')

  const [typess, setTypess] = useState(typesss)
  console.log(typess,'--',typesss)

  const [viewGou, setViewGou] = useState(typess != 2)
  const [viewTip, setViewTip] = useState(false)

  const [myInfo, setMyInfo] = useState('获取验证码')
  const { isMobile } = useMatchBreakpoints()


  const { toastSuccess, toastError } = useToast()

  const [lodding, setLodding] = useState(false)
  //倒计时  start
  const [time, setTime] = useState(0) //倒计时时间
  const timeRef = useRef(null) //设置延时器

  useEffect(() => {
    //如果设置倒计时且倒计时不为0
    if (time && time !== 0)
      timeRef.current = setTimeout(() => {
        setTime((time) => time - 1)
      }, 1000)
    //清楚延时器
    return () => {
      clearTimeout(timeRef.current)
    }
  }, [time])

  console.log('time', time)

  useEffect(() => {
    const inviteCode = localStorage.getItem('invite_address')
    if (inviteCode) {
      setYqm(inviteCode.substring(0, 4))
    }
  }, [])
  //倒计时 end
  //获取验证码
  const getSms = async () => {
    console.log('123123123', 123123123)
    const smsInfo = new SmsInfo()

    const regex = /^1[3456789]\d{9}$/ //使用此正则即可
    if (!regex.test(phone)) {
      toastError('Error', '手机格式错误!')
      return
    }
    smsInfo.mobile = phone
    smsInfo.smsmode = '0'
    const result = await SendSms(smsInfo)

    if (result?.success) {
      toastSuccess('Success', '短信发送成功！')
      setTime(60)
    } else if (result?.message) {
      toastError('Error', result?.message)
      setTime(60)
    }

    console.log('result', result)
  }

  const login = async () => {
    setViewTip(false)

    const regex = /^1[3456789]\d{9}$/ //使用此正则即可
    if (!regex.test(phone)) {
      toastError('Error', '手机格式错误!')
      return
    }

    if (!yzm) {
      toastError('Error', '请填写验证码!')
      return
    }

    if (!viewGou) {
      //
      setViewTip(true)
      setTimeout(() => {
        setViewTip(false)
      }, 4000)
      return
    }

    const smsInfo = new SmsInfo()
    smsInfo.mobile = phone
    smsInfo.captcha = yzm
    smsInfo.types = typess.toString()
    if (typess == 2) {
      if (yqm) {
        smsInfo.inviteCode = base36_to_num(yqm) + ''
      } else {
       /*  toastError('Error', '邀请码错误！')
        return */
      }
    }

    const result = await phoneLogin(smsInfo)

    if (result === 'true') {
      if (typess == 1) {
        toastSuccess('Success', '登陆成功！')
        location.href = WASAI_URL
      }
      if (typess == 2) {
        toastSuccess('Success', '注册成功！')
        location.href = WASAI_URL
      }

      setLoginView(0)
    } else {
      toastError('Error', result)
    }

    console.log('result', result)
  }
  /*手机号 */
  const onInputChange = (e) => {
    if (e) {
      setPhone(e)
    }
  }

  /*验证码 */
  const onInputChangeY = (e) => {
    if (e.length <= 6) {
      setYzm(e)
    }
  }

  /*邀请码 */

  const onInputChangeYQ = (e) => {
    if (e) {
      setYqm(e)
    }
  }

  return (
    <MaxPage>
      <StyledModalContainer>
        <Row>
          <Image
            style={{
              float: 'right',
              top: isMobile ? '-25px' : '10px',
              right: isMobile ? '20px' : '30px',
            }}
            width={30}
            height={30}
            src={'./assets/img/close.png'}
            onClick={() => {
              console.log('login--view')
              setLoginView(0)
            }}
          />
        </Row>
        {isMobile && (
          <Image
            mt={3}
            style={{ width: '100%' }}
            width={498}
            height={60}
            src={'./assets/img/hii.png'}
          />
        )}

        <Flex style={{ overflow: 'view' }}>
          <PhoneDiv>
            <Image
              mt={80}
              width={400}
              height={400}
              src={'./assets/img/600x600G.gif'}
            />
          </PhoneDiv>

          <RowRight typess={typess}>
            <Row>
              <Flex>
                <Image
                  mt={4}
                  width={20}
                  height={25}
                  src={'./assets/img/people.png'}
                />
                <RowInput>
                  <Text ml={0} p={10} color={'#000'}>
                    +86 |
                  </Text>
                  <RowRightInput
                    value={phone}
                    onChange={(e) => {
                      onInputChange(e)
                    }}
                    placeholder={'输入手机号'}
                  />
                </RowInput>
              </Flex>
            </Row>
            <Row>
              <Flex>
                <Image
                  mt={3}
                  width={20}
                  height={25}
                  src={'./assets/img/ps.png'}
                />
                <RowInput>
                  <RowRightInput
                    value={yzm}
                    onChange={onInputChangeY}
                    placeholder={'输入验证码'}
                  />
                  <Text
                    ml={-100}
                    p={10}
                    color={'#77ACEC'}
                    onClick={() => {
                      getSms()
                    }}
                  >
                    {time > 1 && time}
                    {time < 1 && '获取验证码'}
                  </Text>
                </RowInput>
              </Flex>
            </Row>

            {typess == 2 && (
              <Row>
                <Flex>
                  <Image
                    width={20}
                    height={25}
                    mt={3}
                    src={'./assets/img/head.png'}
                  />
                  <RowInput>
                    <RowRightInput
                      value={yqm}
                      onChange={onInputChangeYQ}
                      placeholder={'邀请码'}
                    />
                  </RowInput>{' '}
                </Flex>
              </Row>
            )}
            <Row
              style={{
                textAlign: 'center',
                display: 'flex',
                height: '30px',
              }}
            >
              <RowCenter
                onClick={() => {
                  setViewGou(!viewGou)
                }}
              >
                {viewGou && (
                  <Image width={15} height={15} src={'./assets/img/gou.png'} />
                )}
              </RowCenter>
              <a
                style={{
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '12px',
                }}
                target={'_blank'}
                href="https://doc.wasai.space/2.html"
              >
                《阅读并同意以下协议》
              </a>
              {viewTip && <Tooltip viewTip={viewTip}>请勾选并同意协议</Tooltip>}
            </Row>
            <Row>
              <RowRightButton onClick={login}>
                同意协议并{typess == 2 ? '注册' : '登录'}
              </RowRightButton>
            </Row>

           {/*  <RowAbs>
              <Text  onClick={() => {
                if (typess == 1) { setTypess(2) }
                if (typess == 2) { setTypess(1) }
              }} mt={2}>{typess == 1 ? '注册' : '登录'} </Text>
            </RowAbs> */}

             <Row  >  
               {typess==1&& <Text style={{paddingLeft:"100px"}} fontSize='14px'  onClick={() => {
                  if (typess == 1) { setTypess(2) } 
                }} mt={2}>{  '立即注册'  } </Text>}
            </Row> 
          </RowRight>
        </Flex>
      </StyledModalContainer>
    </MaxPage>
  )
}

export default Login
