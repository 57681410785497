import { BuyOrder } from 'hooks/modules/BuyOrder'
import { payConfirm, WASAI_URL } from 'hooks/RaidConfig'
import MyContext from 'view/common/CommonProvider'

import {
  Button,
  Flex,
  Image,
  InjectedModalProps,
  LinkExternal,
  Text,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'

import React, { useEffect, useRef, useState, useContext } from 'react'
import styled from 'styled-components'

import { Input } from 'antd-mobile'
import useToast from 'hooks/useToast'

const MaxPage = styled.div`
  position: fixed;
  background: #505050c7;
  width: 100vw;
  height: 100vh;
  z-index: 300;
`

const StyledModalContainer = styled.div`
  position: fixed;
  background: url('./assets/img/box3.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 30px;
  width: 500px;
  height: 510px;
  margin: auto;
  top: -100px;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const Row = styled.div`
  width: 100%;
  color: #fff;
  font-size: 20px;
`

const PhoneDiv = styled.div`
  width: 300px;
  position: relative;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const PhoneDiv2 = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center; /* 水平居中 */
`

const PhoneDiv3 = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center; /* 水平居中 */
`

const ButtonConfirm = styled(Button)`
  background: url('./assets/img/buttons.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 173px;
`

const Buttonback = styled(Button)`
  background: url('./assets/img/back.png');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 289px;
  height: 44px;
`

interface ModalProps extends InjectedModalProps {
  buyorderId
  payView: boolean
}

const PayCard: React.FC<ModalProps> = ({ buyorderId, payView }) => {
  const { toastSuccess, toastError } = useToast()
  const { isMobile } = useMatchBreakpoints()
  const [lodding, setLodding] = useState(false)
  //倒计时  start
  const [time, setTime] = useState(0) //倒计时时间
  const timeRef = useRef(null) //设置延时器

  const [link, setLink] = useState('')

  const [viewConfirm, seViewConfirm] = useState(payView)

  const { setPayConfrimView } = useContext(MyContext)
  console.log('time', time)

  const buyConfirm = async () => {
    const buyOrder = new BuyOrder()
    //直接支付
    buyOrder.id = buyorderId
    const result = await payConfirm(buyOrder)

    if (result?.result?.buyOrder?.status == 1) {
      seViewConfirm(false)
    } else {
      //无论成功与否都进行关闭
      setPayConfrimView('')
      localStorage.setItem('payConfrim', '')
      location.href = WASAI_URL
    }
  }

  const buyCancel = async () => {
    //无论成功与否都进行关闭
    setPayConfrimView('')
    localStorage.setItem('payConfrim', '')
    location.href = WASAI_URL
  }

  return (
    <MaxPage>
      <StyledModalContainer>
        <Flex style={{ overflow: 'hidden' }}>
          <PhoneDiv>
            <Image
              mt={3}
              style={{ width: '100%' }}
              width={300}
              height={20}
              src={'./assets/img/top.png'}
            />
          </PhoneDiv>
        </Flex>

        <Flex style={{ overflow: 'hidden' }}>
          <PhoneDiv>
            <Image width={300} height={300} src={'./assets/img/600x600G.gif'} />
          </PhoneDiv>
        </Flex>

        {!viewConfirm && (
          <>
            <Flex style={{ overflow: 'hidden' }}>
              <PhoneDiv2>
                <Image width={50} height={50} src={'./assets/img/duigou.png'} />
              </PhoneDiv2>
            </Flex>

            <Flex style={{ overflow: 'hidden', textAlign: 'center' }}>
              <PhoneDiv>
                <Text mt={1} textAlign={'center'}>
                  恭喜您成为哇塞星球玩家，静待启航！
                </Text>
              </PhoneDiv>
            </Flex>

            <Flex style={{ overflow: 'hidden', textAlign: 'center' }}>
              <PhoneDiv3>
                <Buttonback onClick={buyCancel} mt={10}>
                  返回首页
                </Buttonback>
              </PhoneDiv3>
            </Flex>
          </>
        )}

        {viewConfirm && (
          <Flex style={{ overflow: 'hidden', textAlign: 'center' }}>
            <PhoneDiv3>
              <ButtonConfirm onClick={buyConfirm} mt={10}>
                我已付款
              </ButtonConfirm>
              <ButtonConfirm onClick={buyCancel} mt={10} ml={10}>
                未付款
              </ButtonConfirm>
            </PhoneDiv3>
          </Flex>
        )}
      </StyledModalContainer>
    </MaxPage>
  )
}

export default PayCard
