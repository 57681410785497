import { submitWithdraw } from 'hooks/HRaidConfig'
import { num_to_base36, wasaiPerson } from 'hooks/RaidConfig'
import React, { useEffect, useState, useContext, useRef } from 'react'
import MyContext from './CommonProvider'

import { fastPayConfirm, WASAI_URL } from 'hooks/RaidConfig'

import { BuyOrder } from 'hooks/modules/BuyOrder'

import { Deposit } from 'hooks/modules/Deposit'
import PayConfirm from '../lanhu_huaban2/PayConfirm'

import styled from 'styled-components'

import {
  Button,
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalTitle,
  Text,
  useMatchBreakpoints,
  Image,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 250px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const BossSonRow = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
  margin-top: 20px;
  margin-left: 15%;
`

const BossSonTopRow = styled.div`
  border-bottom: 1px solid white;
  margin-top: 30px;
  display: flex;
  width: 95%;
  height: 42.5px;
  background: #ffffff;
  border-radius: 15px;
  margin-left: 2.5%;
`
const BossText = styled(Text)`
  font-size: 18px;
  color: #000;
  margin-left: 10px;
  font-weight: 400;
  letter-spacing: 4px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  text-align: justify;
  text-justify: inter-word;
  width: 98px;
`

const BossInput = styled(Input)`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc0;
  outline: none;
  padding: 10px;
  width: 250px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  ::placeholder {
    font-size: 14px;
  }
`

interface ModalProps {
  orderId: string
  onDismiss: () => void
}

const FastPayConfirm: React.FC<ModalProps> = ({ onDismiss, orderId }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [phone, setPhone] = useState('')
  const [smsCode, setSmsCode] = useState('')

  const [payConfirm, setPayConfirm] = useState(false)

  const onInputSmsCode = (e) => {
    if (e.currentTarget.value.length <= 6) {
      setSmsCode(e.currentTarget.value)
    }
  }

  const fastPayConfirmInit = async () => {
    //绑定卡
    const buyOrder = new BuyOrder()

    buyOrder.id = orderId
    if (smsCode) {
      buyOrder.payUrl = smsCode
    } else {
      toastError('请填写验证码！')
      return
    }

    const result = await fastPayConfirm(buyOrder)
    if (result?.message) {
      toastError(result?.message)
    } else {
      toastSuccess('支付成功！')
      setPayConfirm(true)
    }
  }

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'立即支付'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>
      {payConfirm && <PayConfirm buyorderId={1} payView={false} />}

      <BossSonTopRow>
        <BossText>验证码:</BossText>

        <BossInput
          onChange={onInputSmsCode}
          value={smsCode}
          placeholder="请输入验证码"
        />
      </BossSonTopRow>

      <BossSonRow>
        <Button
          style={{ width: '160px', background: 'rgb(211 49 187)' }}
          onClick={fastPayConfirmInit}
        >
          确认
        </Button>
      </BossSonRow>
    </StyledModalContainer>
  )
}
export default FastPayConfirm
