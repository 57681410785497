import { buyNft, queryTotalNft, wasaiPerson } from 'hooks/RaidConfig'
import MyContext from '../common/CommonProvider'

import { BuyOrder } from 'hooks/modules/BuyOrder'

import {
  Button,
  Flex,
  Image,
  InjectedModalProps,
  Progress,
  Text,
  useMatchBreakpoints, 
} from '@pancakeswap/uikit'

import React, {
  Component,
  useContext,
  useEffect,
  useState
} from 'react'

import {
  Input
} from 'antd-mobile'
import useToast from 'hooks/useToast'

import styled, { createGlobalStyle, css } from 'styled-components'

import Login from '../lanhu_huaban2/LoginNew'

import CommonHead from '../lanhu_huaban2/CommonHead2'
import Footer from '../common/Footer'


interface ModalProps extends InjectedModalProps { }
const IdoStyle = createGlobalStyle<{ loginView: number }>`

html,body{
 
}

 body {
  background: url('./assets/img/buybg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 
  
}
#root{
  display: block; /* 或 inline 或 inline-block，视具体情况而定 */

  min-width: auto;
  max-width: 100%;
}

 
 

${({ loginView }) =>
    loginView == 0 &&
    css`
    .adm-input-element {
      color: #fff !important;
      text-align: center;
    }
  `}



`
  

const StyledPage = styled.div`
  min-height: 960px; 
  padding-top: 0;
  padding-bottom: 16px;
  overflow-y: auto;
   
  background: url('./assets/img/info_bg.png') center/cover no-repeat;
  min-width: auto;
  max-width: 100%;

  
`;

const BuyBoxBox = styled.div`
  width: 80%;
  background: url('./assets/img/ju2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  //background-color: rgba(255, 255, 255, 0.5); /* 设置背景颜色的不透明度为0.5 */
  backdrop-filter: blur(10px); /* 设置模糊效果，可以调整像素值来改变模糊程度 */
  -webkit-backdrop-filter: blur(10px); /* 兼容性：针对Safari浏览器 */

  margin: auto;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;

  margin-top: 20px;
  @media screen and (max-width: 968px) {
    width: 90%;
    border-radius: 20px;
  }
`

 
const RowText = styled.div`
  margin: auto;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
`

interface IndividualNFTPageProps { }

const Info: React.FC = ({ }) => {
  const [loginView, setLoginView] = useState(0)
  const [payCardView, setPayCardView] = useState(0)

  const [readView, setReadView] = useState(true)
  const [buyorder, setBuyorder] = useState<any>()
  const [wasaiInvite, setWasaiInvite] = useState<any>()
  const [nftNumber, setNftNumber] = useState('1')
  const [tips, setTips] = useState('')
  const [progress, setProgress] = useState(1)
  const { isMobile } = useMatchBreakpoints()

  const {
    payDespointConfrimView,
    payConfrimView,
    teamCardView,
    setTeamCardView,
  } = useContext(MyContext)
 

  /*购买数量 */
 

  console.log('payConfirm-ppp', payConfrimView)

  const queryData = async () => {
    const result = await wasaiPerson()
    if (result?.result) {
      setWasaiInvite(result?.result?.wasaiInvite)

      if (result?.result?.wasaiInvite?.userLevel == 1) {
        setTips('一次性购买 5 个及以上，可享受 6.66 折福利，单价 1999一个！')
      }

      if (result?.result?.wasaiInvite?.userLevel == 2) {
        setTips('一次性购买 70个及以上，可享受 4.76折福利，单价 1429一个！')
      }
    }
  }

  useEffect(() => {
    queryData()
  }, [])

 

  return (
    <>
      <StyledPage>
      <IdoStyle loginView={loginView} />

        {loginView >= 1 && (
          <Login setLoginView={setLoginView} typesss={loginView} />
        )}


        <CommonHead setLoginView={setLoginView} loginView={loginView} />

        <BuyBoxBox
          style={{ minHeight: '292px', padding: '20px', overflow: 'hidden',marginTop:'180px' }}
        >
          <RowText>
            <Text fontSize="25px" textAlign={'center'}>
              作品信息
            </Text>
          </RowText>
          <RowText style={{ marginTop: '5px' }}>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              作品编号：哇塞星球
            </Text>
          </RowText>
          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              合约地址:{' '}
              <a href="https://evm.confluxscan.net/token/0xd8f5aa267d3a376afa7cb16729382e4d3cc6b2e4?tab=NFT">
                0xd8f5aa267d3a376afa7cb16729382e4d3cc6b2e4
              </a>
            </Text>
          </RowText>
          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              品牌使命： 哇塞星球-让你梦想成真
            </Text>
          </RowText>

          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              品牌愿景：哇塞星球-打造中国数藏第一品牌
            </Text>
          </RowText>
          <RowText>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              品牌发行： 重庆哇塞宇宙数字科技有限公司
            </Text>
          </RowText>
        </BuyBoxBox>
 

        <BuyBoxBox style={{ minHeight: '292px', padding: '20px' }}>
          <RowText>
            <Text fontSize="25px">背景故事</Text>
          </RowText>

          <RowText style={{ marginTop: '0px' }}>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              &nbsp;&nbsp;&nbsp; &nbsp;
              2023年全新的一年。一股神秘力量无形之中 引领着一群心怀梦想的年轻人聚集到重庆。随着 解放碑钟声的敲响，突然！天空中出现一艘可以 带领着我们前往哇塞星球的宇宙飞船！飞船内有 一排排望不到头的方舱，还有一个巨型能量泉， 用来启动飞船，能量来源于各位梦想者们手中的 能量盒子。经过梦想者们不断的分享，最终集结 完十万梦想能量！来自全国各地的梦想者们，陆 陆续续登陆飞船，准备启航前往哇塞星球！
              <br/>&nbsp;&nbsp;&nbsp; &nbsp;
              十万个方舱已全部完成注能，而没有争取到 方舱的梦想者们聚集在地面上，不约而同的发起 了以物置换方舱，只为拥有前往哇塞星球的一席 之舱，而登舱的梦想者们也因此开始实现了各自 的梦想...... 
              <br/>&nbsp;&nbsp;&nbsp; &nbsp;
              这就是梦想的力量！这就是哇塞星球！让你梦想 成真！
            </Text>
          </RowText>
        </BuyBoxBox>

        <BuyBoxBox style={{ minHeight: '292px', padding: '20px' }}>
          <RowText>
            <Text fontSize="25px">关于数字藏品</Text>
          </RowText>

          <RowText style={{ marginTop: '0px' }}>
            <Text fontSize="13px" fontWeight={300} lineHeight={2}>
              &nbsp;&nbsp;&nbsp; &nbsp;
              数字藏品可以通俗理解为区块链凭证。通常是指开发者在区块链的技术下根据不同公链或者
              联盟链的协议所发行，特性为不可分割、不可替代、独一无二。数字藏品常见于文化艺术品领域、
              知识产权的链上发行、流转、确权等作用，能有效保护链上知识产权，防止篡改、造假等，是区
              块链技术的一类场景应用。
            </Text>
          </RowText>
        </BuyBoxBox>


         <Footer />
      </StyledPage>
    </>
  )
}

class DDS_lanhupage_home extends Component {
  render() {
    return (
      <>
        <Info />
      </>
    )
  }
}

export default DDS_lanhupage_home
