import React, { useEffect, useState } from 'react'
import './assets/Modal.css'; // 引入样式文件
import useToast from 'hooks/useToast'
const Window = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  const isSafari = () => {
    const userAgent = navigator.userAgent;
    return /^((?!chrome|android).)*safari/i.test(userAgent);
  };

  const { toastSuccess, toastError } = useToast()
  const [viewOpen, setViewOpen] = useState(false)

  

  async function copyTextToClipboard() {
    // Text you want to copy
    const text = "https://h5.wsxq.space";
  
    // New Clipboard API
    if (navigator.clipboard && window.isSecureContext) {
      // Navigator.clipboard is available and secure
      try {
        await navigator.clipboard.writeText(text);
        console.log('Text copied to clipboard');
        toastSuccess("复制成功");
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    } else {
      // Fallback method for older browsers
      try {
        // Create a temporary text area element
        const textArea = document.createElement("textarea");
        textArea.value = text;
  
        // Prevent scrolling to bottom of the page on Microsoft Edge
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
  
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
  
        // Copy text
        document.execCommand('copy');
  
        // Cleanup
        document.body.removeChild(textArea);
  
        console.log('Text copied to clipboard');
        toastSuccess("复制成功");
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    }
  }
  

  const handleIOSDownloadClick = () => {
    const iosDownloadUrl = "https://h5.wsxq.space";
    if (isSafari()) {
      // 如果是Safari，直接打开链接
      window.open(iosDownloadUrl, "_blank");
    } else {
      // 如果不是Safari，显示弹窗让用户复制链接
      setViewOpen(true)
      // 实际应用中，可能需要更复杂的弹窗逻辑来允许用户复制文本
    }
  };


    // 定义点击事件处理函数
    const handleDownload = () => {
        // 可以添加一些逻辑，比如下载统计或验证等
        window.location.href ="https://doc.wsxq.space/wasai.apk"; // 触发下载
      };
  

  return (
    <div className="modal-overlay" onClick={onClose}>
      {!viewOpen&&<div className="modal-content" onClick={e => e.stopPropagation()}>
            <img src={"./assets/img/window.png"} alt="Modal" className="modal-image"/>
            <div className="icons-container">
            
                <img src={"./assets/img/dlogo.png"} width={"272px"} height={"272px"} alt="Modal" className="modal-logo"/>
                <span className="d_span">哇塞星球官方APP下载</span>
                <img src={"./assets/img/ios_download.png"} alt="Modal" className="ios_download"  onClick={handleIOSDownloadClick}/>
                <img src={"./assets/img/apk_download.png"} alt="Modal" className="apk_download"  onClick={handleDownload}  />
            </div>
        </div>} 


        {viewOpen&&<div className="modal-content" onClick={e => e.stopPropagation()}>
            <img src={"./assets/img/windowios.png"} alt="Modal" className="modal-image"/>
            <div className="icons-container"> 
                <img src={"./assets/img/ddlog.png"} width={"272px"} height={"272px"} alt="Modal" className="modal-logo"/>
              
                <img src={"./assets/img/copyy.png"} alt="Modal" className="apk_download"    onClick={copyTextToClipboard}  />
                <span className="d_span2">请在 Safari 浏览器打开此网站进行下载！</span>
            </div>
        </div>} 
      
    </div>
  );
};

export default Window;
