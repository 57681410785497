import React, { useState, useEffect, Component } from 'react'
import { queryCardInfo, bandCard } from 'hooks/RaidConfig'

import { UserCard } from 'hooks/modules/UserCard'

import styled from 'styled-components'
import { loginOut } from 'hooks/RaidConfig'
import { CopyButton } from './CopyButton'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  LinkExternal,
  Text,
  Alert,
  Button,
  InjectedModalProps,
  CloseIcon,
  ModalTitle,
  IconButton,
  Input,
  UserMenu,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
const InfoBody = styled.div`
  width: 300px;
  min-width: 300px;
  height: 300px;
`

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 490px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const Wrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dropdown};
  border-radius: 16px;
  position: relative;
`

const Address = styled.div`
  flex: 1;
  position: relative;
  padding-left: 16px;

  & > input {
    background: transparent;
    border: 0;
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  &:after {
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
`

const BossSonRow = styled.div`
  border: dashed 1px #2c2a2a;
  width: 100%;
  padding: 3px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
  display: flex;
`

const BossSonRow2 = styled.div`
  width: 100%;
  padding: 3px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #fff;
  display: flex;
`

const StyleInput = styled(Input)`
  width: 260px;
  background-color: rgba(0, 0, 0, 0);
  border:1px solid rgba(0, 0, 0, 0)

  border-bottom: 1px solid #ffffff85;
  text-align: left;
  color: #fff !important;
  font-color: #fff;
  margin-top: -4px;
  @media screen and (max-width: 968px) {
    margin-top: -3px;
  }
`

interface ModalProps extends InjectedModalProps {}

const UserCardInfo: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()
  const [name, setName] = useState('')
  const [cardNo, setCardNo] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankAddress, setBankAddress] = useState('')

  const [userCardQuery, setUserCardQuery] = useState<any>()

  const queryData = async () => {
    const result = await queryCardInfo()
    if (result?.result?.wasaiUserCard) {
      setName(result?.result?.wasaiUserCard.name)
      setBankName(result?.result?.wasaiUserCard.bankName)
      setBankAddress(result?.result?.wasaiUserCard.bankAddress)
      setCardNo(result?.result?.wasaiUserCard.cardNo)

      setUserCardQuery(result?.result?.wasaiUserCard)
    }
  }

  useEffect(() => {
    queryData()
  }, [])

  //确认绑定
  const handleKu = async () => {
    const userCard = new UserCard()

    if (!name) {
      toastError('请填写持卡人！')
      return false
    }
    if (!cardNo) {
      toastError('请填写卡号！')
      return false
    }
    if (!bankName) {
      toastError('请填持卡银行名称！')
      return false
    }
    if (!bankAddress) {
      toastError('请填写开户支行！')
      return false
    }

    userCard.bankName = bankName
    userCard.bankAddress = bankAddress
    userCard.name = name
    userCard.cardNo = cardNo

    const resp = await bandCard(userCard)
    if (resp.success) {
      toastSuccess('绑定成功！')
      //  queryData()
    } else {
      //  queryData()
      toastError(resp?.message)
    }
  }

  const onInputChangeName = (e) => {
    setName(e.currentTarget.value)
  }
  const onInputChangeCardNo = (e) => {
    setCardNo(e.currentTarget.value)
  }
  const onInputChangeBankName = (e) => {
    setBankName(e.currentTarget.value)
  }
  const onInputChangeBankAddress = (e) => {
    setBankAddress(e.currentTarget.value)
  }

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'结算银行卡'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>

      <BossSonRow>
        <Text>持 卡 人:</Text>
        <StyleInput
          value={name}
          onChange={onInputChangeName}
          placeholder="请输入您的真实姓名"
        />
      </BossSonRow>
      <BossSonRow>
        <Text>银行卡号:</Text>
        <StyleInput
          value={cardNo}
          onChange={onInputChangeCardNo}
          placeholder="请输入正确的银行卡号"
        />
      </BossSonRow>
      <BossSonRow>
        <Text>银行名称:</Text>
        <StyleInput
          value={bankName}
          onChange={onInputChangeBankName}
          placeholder="请输入持卡银行名称"
        />
      </BossSonRow>
      <BossSonRow>
        <Text>开 户 行:</Text>
        <StyleInput
          value={bankAddress}
          onChange={onInputChangeBankAddress}
          placeholder="请输入持卡银行的开户支行"
        />
      </BossSonRow>

      <BossSonRow2 style={{ display: 'block', padding: '3px 20px' }}>
        <Button
          mt={4}
          onClick={handleKu}
          style={{ marginLeft: '35%', background: 'rgb(211 49 187)' }}
        >
          确认绑定
        </Button>
        <Text mt={4} textAlign={'center'} fontSize={'8px'}>
          提示:绑定成功后无法自行更改，如需更改请联系官方工作人员!
        </Text>
      </BossSonRow2>
    </StyledModalContainer>
  )
}
export default UserCardInfo
