


/*提现业务 */
export class BuyOrder {
  /**id*/
  /**主键*/

  public id!: string;
  /**创建人*/

  public createBy!: string;
  /**创建日期*/

  public createTime!: Date;
  /**更新人*/

  public updateBy!: string;
  /**更新日期*/

  public updateTime!: Date;
  /**所属部门*/

  public sysOrgCode!: string;
  /**购买的流水*/

  public tx!: string;
  /**确认状态*/

  public status!: number;
  /**购买数量*/

  public buyNumber: number;
  /**购买金额*/

  public payNumber: number;
  /**nft_tx*/

  public nftTx!: string;
  /**nft_确认状态*/

  public nftStatus: number;
  /**购买类型*/

  public type: number;
  /**uid*/

  public uid!: string;
  /**上级邀请码*/

  public inviteCode!: string;
  /**上级uid*/

  public parentUid!: string;

  /**payUrl/smscode*/

  public payUrl!: string;


  /**银行卡号*/

  public bindCard!: string;

  /**银行名称e*/

  public bank!: string;
}

