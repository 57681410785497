import { submitWithdraw } from 'hooks/HRaidConfig'
import { num_to_base36, wasaiPerson } from 'hooks/RaidConfig'
import React, { useEffect, useState, useContext } from 'react'
import MyContext from './CommonProvider'

import { userDespoint, WASAI_URL } from 'hooks/RaidConfig'

import { Deposit } from 'hooks/modules/Deposit'

import styled from 'styled-components'
import FastPayConfirmDeposit from './FastPayConfirmDeposit'

import {
  Button,
  CardHeader,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  InjectedModalProps,
  Input,
  ModalTitle,
  Text,
  useMatchBreakpoints,
  Image,
} from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'

const StyledModalContainer = styled.div`
  position: fixed;
  border-radius: 30px;
  background-color: #000;
  width: 417px;
  height: 400px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  min-width: 100px;
  max-width: calc(100% - 10px);
  max-height: 100%;
  z-index: 300;
  float: left;
`

const BossSonRow = styled.div`
  border: 1px #2c2a2a;
  width: 100%;
  padding: 3px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #fff;
  margin-top: 40px;
`

interface ModalProps extends InjectedModalProps {}

const ConectUS: React.FC<ModalProps> = ({ onDismiss }) => {
  //退出登录
  const { toastSuccess, toastError } = useToast()

  const { teamCardView, setTeamCardView } = useContext(MyContext)
  const [isloadding, setIsloadding] = useState(false)

  const [view, setView] = useState(0)

  const [wasaiInviteChilds, setWasaiInviteChilds] = useState<any>([])

  //查询账户余额

  return (
    <StyledModalContainer>
      <CardHeader style={{ height: '70px' }}>
        <Flex mt={-3}>
          <ModalTitle>
            <Heading>{'微信公众号'}</Heading>
          </ModalTitle>
          <IconButton variant="text" onClick={onDismiss}>
            <CloseIcon width="24px" color="text" />
          </IconButton>
        </Flex>
      </CardHeader>

      <BossSonRow>
        <Image mt={20} width={200} height={200} src="./assets/img/weixin.jpg" />
      </BossSonRow>
    </StyledModalContainer>
  )
}
export default ConectUS
